import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { hmac256 } from '../../utils/common';
import { snakeToCamelRec } from '../../utils/converters';

const facebookBaseUrl = process.env.REACT_APP_FACEBOOK_API;

export const facebookApi = createApi({
  reducerPath: 'facebookApi',
  baseQuery: fetchBaseQuery({
    baseUrl: facebookBaseUrl,
    prepareHeaders: (headers) => {
      headers.set('Accept', 'application/json');
      return headers;
    },
  }),
  tagTypes: ['campaigns', 'adsets', 'videos'],
  endpoints: (builder) => ({
    getApplication: builder.mutation({
      query: (id) => {
        const path = '/facebook/apps/' + id;
        return {
          url: path,
          headers: { Authorization: hmac256(facebookBaseUrl + path) },
        };
      },
      transformResponse: (response) => {
        if (response.error) return response;
        response.authorized_adaccounts = response?.authorized_adaccounts?.data || [];
        return response;
      },
    }),

    listClients: builder.query({
      query: () => ({
        url: '/facebook/accounts',
        headers: { Authorization: hmac256(facebookBaseUrl + '/facebook/accounts') },
      }),
      transformResponse: (response) => {
        return Object.values(response);
      },
    }),

    listCampaigns: builder.query({
      query: (id) => {
        const path = '/facebook/campaigns?account_id=' + id + '&effective_status=[%22ACTIVE%22%2C%22PAUSED%22]';
        return {
          url: path,
          headers: {
            Authorization: hmac256(facebookBaseUrl + path),
          },
        };
      },
      providesTags: (result, error, id) => (result ? [{ type: 'campaigns', id }] : []),
      transformResponse: (response) => {
        return Object.values(response);
      },
    }),

    getCampaign: builder.query({
      query: ({ id, campaignId }) => {
        const path =
          '/facebook/campaigns/' + campaignId + '?account_id=' + id + '&effective_status=[%22ACTIVE%22%2C%22PAUSED%22]';
        return {
          url: path,
          headers: {
            Authorization: hmac256(facebookBaseUrl + path),
          },
        };
      },
      providesTags: (result, error, id) => (result ? [{ type: 'campaigns' }] : []),
      transformResponse: (response) => {
        return response[0];
      },
    }),

    searchCampaigns: builder.query({
      query: ({ id, q }) => {
        const params = new URLSearchParams({
          account_id: id,
          effective_status: JSON.stringify(['ACTIVE', 'PAUSED']),
          q: q,
          max_results: 25,
        });
        const path = `/facebook/campaigns/search?${params.toString()}`;
        return {
          url: path,
          headers: {
            Authorization: hmac256(facebookBaseUrl + path),
          },
        };
      },
      providesTags: (result, error, id) => (result ? [{ type: 'campaigns' }] : []),
      transformResponse: (response) => {
        return Object.values(response);
      },
    }),

    listAdSets: builder.query({
      query: ({ id }) => {
        const path = '/facebook/adsets/' + id;
        return {
          url: path,
          headers: { Authorization: hmac256(facebookBaseUrl + path) },
        };
      },
      providesTags: (result, error, id) => (result ? [{ type: 'adsets', id }] : []),
      transformResponse: (response) => {
        return Object.values(response || {});
      },
    }),

    listStats: builder.query({
      query: ({ id, params }) => {
        const report_params = {
          default_summary: 'true',
          ...params,
        };
        const path = '/facebook/stats/' + id + '?report=' + encodeURIComponent(JSON.stringify(report_params));
        return {
          url: path,
          headers: { Authorization: hmac256(facebookBaseUrl + path) },
        };
      },
      providesTags: (result, error, id) => (result ? [{ type: 'stats', id }] : []),
      transformResponse: (response) => {
        for (let row of response.data) {
          if (row.actions) {
            for (const action_type_row of row.actions) {
              row[`actions.${action_type_row['action_type']}`] = action_type_row['value'];
            }
          }
        }
        if (response.summary.actions) {
          for (const action_type_row of response.summary.actions) {
            response.summary[`actions.${action_type_row['action_type']}`] = action_type_row['value'];
          }
        }
        return response;
      },
    }),

    createCampaign: builder.mutation({
      query: ({ clientId, ...data }) => ({
        url: '/facebook/campaigns/' + encodeURIComponent(clientId + '/campaigns'),
        method: 'POST',
        body: data,
        headers: { Authorization: hmac256(data) },
      }),
      invalidatesTags: (result, error, args) =>
        result ? [{ type: 'campaigns' }, { type: 'adsets', id: args.clientId }] : [],
      transformResponse: (response) => snakeToCamelRec(response),
    }),

    updateCampaign: builder.mutation({
      query: ({ id, ...data }) => ({
        url: '/facebook/campaigns/' + id,
        method: 'POST',
        body: data,
        headers: { Authorization: hmac256(data) },
      }),
      invalidatesTags: (result, error, args) =>
        result && !error && !result?.error ? [{ type: 'campaigns' }, { type: 'adsets', id: args.id }] : [],
      transformResponse: (response) => snakeToCamelRec(response),
    }),

    upsertAdSet: builder.mutation({
      query: ({ campaignId, adSetId, clientId, ...adSetData }) => ({
        url: `/facebook/adsets/${campaignId}${adSetId ? `/${adSetId}` : ''}?account_id=${encodeURIComponent(clientId)}`,
        method: 'POST',
        body: adSetData,
        headers: { Authorization: hmac256(adSetData) },
      }),
      invalidatesTags: (result, error, args) =>
        result ? [{ type: 'adsets', id: args.campaignId }, { type: 'adsets' }] : [],
      transformResponse: (response) => snakeToCamelRec(response),
    }),
    getVideo: builder.mutation({
      query: (id) => ({
        url: '/facebook/videos/' + id,
        headers: { Authorization: hmac256(facebookBaseUrl + '/facebook/videos/' + id) },
      }),
      transformResponse: (response) => {
        return Object.values(response);
      },
    }),
    uploadVideos: builder.mutation({
      query: ({ accountId, url, name }) => {
        const data = {
          file_url: url,
          name: name,
        };
        return {
          url: `/facebook/videos?account_id=${accountId}`,
          method: 'POST',
          body: data,
          headers: { Authorization: hmac256(data) },
        };
      },
    }),
    listPages: builder.query({
      query: () => ({
        url: '/facebook/pages',
        headers: { Authorization: hmac256(facebookBaseUrl + '/facebook/pages') },
      }),
      transformResponse: (response) => {
        return Object.values(response);
      },
    }),
    createCreative: builder.mutation({
      query: ({ adset, accountId, data }) => ({
        url: `/facebook/creatives/${adset}?account_id=${accountId}`,
        method: 'post',
        body: data,
        headers: { Authorization: hmac256(data) },
      }),
    }),
    createAd: builder.mutation({
      query: ({ adset, accountId, data }) => ({
        url: `/facebook/ads/${adset}?account_id=${accountId}`,
        method: 'post',
        body: data,
        headers: { Authorization: hmac256(data) },
      }),
    }),
  }),
});

export const {
  useGetApplicationMutation,
  useCreateCampaignMutation,
  useUpdateCampaignMutation,
  useUpsertAdSetMutation,
  useListClientsQuery,
  useListCampaignsQuery,
  useGetCampaignQuery,
  useLazyGetCampaignQuery,
  useSearchCampaignsQuery,
  useLazySearchCampaignsQuery,
  useListAdSetsQuery,
  useListPagesQuery,
  useListStatsQuery,
  useUploadVideosMutation,
  useGetVideoMutation,
  useCreateCreativeMutation,
  useCreateAdMutation,
} = facebookApi;
