import { forwardRef, useImperativeHandle } from 'react';
import InfoIcon from '../../../../Components/Icons/InfoIcon';
import { useUpdateDraftGameMutation } from '../../../../data/api/studioApi';

const PublishView = forwardRef(({ game, studio, handleResult }, ref) => {
  const [updateDraftGame] = useUpdateDraftGameMutation();

  useImperativeHandle(ref, () => ({
    submit: async () => {
      return await updateDraftGame({
        id: game?.id,
        studio_id: studio?.studioId,
        status: 'WATCHED',
      })
        .unwrap()
        .then(() => {
          handleResult(null, true);
        })
        .catch((err) => {
          handleResult(err?.data?.message || 'Something went wrong', null);
        });
    },
  }));

  return (
    <div className="d-flex  flex-column align-items-center">
      <div className="rounded-circle p-5 mb-4  bg-warning-300 text-warning">
        <InfoIcon color="inherit" size={48} />
      </div>
      <h5 className="text-center">Are you sure you want to Publish game "{game?.appName}"?</h5>
      <div className="text-center text-muted">Published game can not be removed!</div>
    </div>
  );
});

export default PublishView;
