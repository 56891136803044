import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import { generatePath, useHistory } from 'react-router-dom';
import Loader from '../../../Components/Loader';
import { Restricted } from '../../../Components/permissions/UserPermissions';
import { R_GAME_INFO } from '../../../constants/routes';
import { studioApi } from '../../../data/api/studioApi';
import PublishView from './Views/PublishView';
import SDKView from './Views/SDKView';
import SetAdjustToken from './Views/SetAdjustToken';
import SetFbAppID from './Views/SetFbAppID';
import SetGameGA from './Views/SetGameGA';
import SetStudioGA from './Views/SetStudioGA';

const PublishDraftGame = ({ id }) => {
  const history = useHistory();
  const { data: games, isLoading: isGamesLoading } = studioApi.endpoints.getGames.useQueryState();
  const game = games?.find((el) => el.id === Number(id)) || {};
  const { data: studioList, isLoading: isStudiosLoading } = studioApi.endpoints.listStudios.useQueryState();
  const studio = studioList?.find((s) => s.studioId === game?.studioId) || {};
  const [show, setShow] = useState(false);
  const [step, setStep] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const STEPS = [
    {
      label: 'GA Studio ID',
      component: SetStudioGA,
      ref: useRef(),
    },
    {
      label: 'GA Game ID',
      component: SetGameGA,
      ref: useRef(),
    },
    {
      label: 'Facebook App ID',
      component: SetFbAppID,
      ref: useRef(),
    },
    {
      label: 'Adjust Token',
      component: SetAdjustToken,
      ref: useRef(),
    },
    {
      label: 'SDK Setup',
      component: SDKView,
      ref: useRef(),
      nextButtonLabel: 'Publish',
      nextButtonVariant: 'success',
    },
    {
      label: 'Confirm',
      component: PublishView,
      ref: useRef(),
      nextButtonLabel: 'Publish Game',
      nextButtonVariant: 'success',
    },
  ];

  const stepData = [studio?.gaStudioId, game?.gaId, game?.fbAppId, game?.adjustAppToken, false];
  const expected = stepData.length;
  const completed = stepData.filter(Boolean).length;

  useEffect(() => {
    // initial step is determined by first not completed step
    if (step === null && game?.id && studio?.studioId) {
      setStep(stepData.findIndex((value) => !value));
    }
  }, [completed, isGamesLoading, isStudiosLoading]);

  const currentStep = STEPS[step || 0];

  const handleNext = async (e) => {
    e.stopPropagation();
    setLoading(true);
    setError(null);
    await currentStep.ref.current?.submit();
  };

  const handleResult = (err, result) => {
    // error
    if (err) {
      setError(err);
      setLoading(false);
      return;
    }
    // published
    if (step === expected) {
      return history.push(generatePath(R_GAME_INFO, { id: game.id }));
    }
    // next step
    setLoading(false);
    setStep(step + 1);
  };

  return (
    <Restricted permission="draftGame.publish">
      <Button className="text-nowrap" variant={'success'} onClick={() => setShow(true)}>
        Publish {completed}/{expected}
      </Button>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Publish Game: {currentStep.label}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center">
              <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
            </div>
          ) : (
            <>
              {error && <Alert variant="danger">{error}</Alert>}
              <currentStep.component ref={currentStep.ref} studio={studio} game={game} handleResult={handleResult} />
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between align-items-center">
          <div>
            <Button variant="secondary" onClick={() => setShow(false)} disabled={loading}>
              Cancel
            </Button>
          </div>
          <div className={`text-muted fs-7 d-flex align-items-center ${step >= expected ? 'd-none' : ''}`}>
            Step {step + 1} of {expected}
          </div>
          <div>
            <Button
              variant="primary"
              onClick={() => setStep(step - 1)}
              disabled={loading || step === 0}
              className="me-2"
            >
              Prev
            </Button>
            <Button variant={currentStep.nextButtonVariant || 'primary'} onClick={handleNext} disabled={loading}>
              {currentStep.nextButtonLabel || 'Next'}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </Restricted>
  );
};

export default PublishDraftGame;
