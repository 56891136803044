import GameInfoCard from '../../../../Components/GameInfoCard';
import OsSelector from '../../../../Components/OsSelector';

const MetricsHeader = ({ data, os, changeOs }) => {
  return (
    <div className="row g-3 g-md-4 mb-3">
      <div className="col-sm-12 col-md-6 col-xl-4">{data && <GameInfoCard os={os} id={data.id} />}</div>
      <div className="col-sm-12 col-md-6 col-xl-8 d-flex justify-content-end">
        {data.multipleOs && <OsSelector value={os} onChange={changeOs} />}
      </div>
    </div>
  );
};

export default MetricsHeader;
