import { generatePath, Link, useParams, useHistory } from 'react-router-dom';
import { R_GAMES } from '../../../constants/routes';
import { Dropdown } from 'react-bootstrap';

function GameStatusFilter({ setData }) {
  let { filter } = useParams();
  const history = useHistory();
  let filtersArray = ['ALL-GAMES', 'DRAFT', 'WATCHED', 'ITERATION', 'PRE-LAUNCH', 'LAUNCHED', 'ARCHIVED'];
  let filtersName = ['All Games', 'Pre-Launch', 'Testing', 'Iteration', 'Soft Launch', 'Live', 'Archived'];
  const active = filter?.toUpperCase() || 'ALL-GAMES';
  const handleSelect = (selectedFilter) => {
    if (active !== selectedFilter) {
      setData({ sortedGames: [], error: '', total: 0 });
      const path = generatePath(R_GAMES, {
        filter: selectedFilter !== 'ALL-GAMES' ? selectedFilter.toLowerCase() : null,
      });
      history.push(path);
    }
  };
  return (
    <>
      <div className="top-bar-tab-btns d-none d-lg-block">
        {filtersArray.map((value, index) => {
          return (
            <Link
              to={generatePath(R_GAMES, { filter: value !== 'ALL-GAMES' ? value.toLowerCase() : null })}
              className={`btn  ${active === value ? 'active' : ''}`}
              key={index}
              onClick={() => {
                if (active !== value) {
                  setData({ sortedGames: [], error: '', total: 0 });
                }
              }}
            >
              {filtersName[index]}
            </Link>
          );
        })}
      </div>
      <div className="d-block d-lg-none">
        <Dropdown onSelect={handleSelect}>
          <Dropdown.Toggle id="dropdown-basic">{filtersName[filtersArray.indexOf(active)]}</Dropdown.Toggle>
          <Dropdown.Menu>
            {filtersArray.map((value, index) => (
              <Dropdown.Item key={index} eventKey={value} active={active === value}>
                {filtersName[index]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
}

export default GameStatusFilter;
