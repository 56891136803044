import { Button, Form, Modal } from 'react-bootstrap';
import Loader from '../../../../../Components/Loader';
import React, { useState } from 'react';
import { confirmAlert } from '../../../../../Components/ConfirmModal';
import EditIcon from '../../../../../Components/Icons/EditIcon';
import { useUpdateGameAdjustAppIdMutation } from '../../../../../data/api/studioApi';

const UpdateAdjustAppIDModal = ({ appId, studioId, adjustAppToken }) => {
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState('');
  const [newAdjustAppToken, setNewAdjustAppToken] = useState(adjustAppToken || '');

  const [updateGameAdjustAppId, { isLoading }] = useUpdateGameAdjustAppIdMutation();

  const handleSubmit = async () => {
    try {
      await updateGameAdjustAppId({
        app_id: appId,
        adjust_app_token: newAdjustAppToken,
        studio_id: studioId,
      }).unwrap();
      confirmAlert({
        variant: 'success',
        title: 'Adjust App Token updated successfully',
        confirmBtn: false,
        cancelText: 'Ok',
      }).catch(() => {});
      setShowModal(false);
      setError('');
    } catch (err) {
      confirmAlert({
        variant: 'danger',
        title: 'Failed to update Adjust App Token',
        desc: err?.data?.message ? err?.data?.message : err?.message ? err.message: undefined,
        confirmBtn: false,
        cancelText: 'Ok',
      }).catch(() => {});
    }
  };
  const handleTextChange = (e) => {
    const text = e.target.value;
    setNewAdjustAppToken(text)
    setError('');
  };

  return (
    <>
      <Button
        variant="outline-light"
        size="sm"
        className="p-1 ms-auto text-nowrap"
        onClick={(e) => {
          setShowModal(true);
        }}
        id="emai-modal-open-button"
      >
        <EditIcon color="currentColor" size={18} />
      </Button>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Adjust App Token </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center">
              <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
            </div>
          ) : (
            <>
              <Form.Group
                className="position-relative me-3 flex-grow-1 flex-basis-0"
                controlId="newid"
              >
                <Form.Control
                  type="text"
                  placeholder="Adjust App Token"
                  value={newAdjustAppToken}
                  isInvalid={!!error}
                  onChange={(e) => handleTextChange(e)}
                  name="adjustapptoken"
                />
                <Form.Control.Feedback tooltip type="invalid">
                  {error}
                </Form.Control.Feedback>
              </Form.Group>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit} disabled={isLoading}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdateAdjustAppIDModal;
