import React from 'react';
import InfoIcon from '../../../../Components/Icons/InfoIcon';
import { abTestsApi, useGetGameAbTestJsonMetricsQuery } from '../../../../data/api/abTestsApi';
import { useParams } from 'react-router-dom';
import { useQuery } from '../../../../utils/customHooks';
import { useSelector } from 'react-redux';
import { gameAbTestByIdSelector } from '../../../../data/slices/abTests';
import { getGameById } from '../../../../data/slices/studio';
import Loader from '../../../../Components/Loader';
import JsonMetricsFilters from './JsonMetricsFilters';
import OverviewMetrics from './Tabs/OverviewMetrics';
import AdsMetrics from './Tabs/AdsMetrics';
import RetentionMetrics from './Tabs/RetentionMetrics';
import PlayTimeMetrics from './Tabs/PlayTimeMetrics';
import IapMetrics from './Tabs/IapMetrics';

function JsonMetricsContainer() {
  const { gameId, testId } = useParams();
  const foundGame = useSelector(getGameById(gameId));
  const abTestSelected = useSelector((state) =>
    gameAbTestByIdSelector(
      abTestsApi.endpoints.getGameAbtests.select({
        bundleId: foundGame?.bundleId,
        store: foundGame?.store === 'amazon' ? 'amazon' : 'google,apple',
      })(state),
      testId,
    ),
  );

  const query = useQuery();
  const country = query.get('country') || '';
  const idfa = query.get('idfa') || 0;
  const build = query.get('build') || '';
  const os = query.get('os') || abTestSelected?.os || 'android';
  const from = query.get('from') || abTestSelected?.startTs.split('T')[0] || '';
  const to = query.get('to') || abTestSelected?.endTs.split('T')[0] || '';
  const network = query.get('network') || '';
  const mediation = query.get('mediation') || 'applovin_max_fb_only,applovin,ironSource,publisher';
  const ads = query.get('ads') || 0;
  const outliers = Number(query.get('outliers')) || 0;
  const campaigns = query.get('campaigns') || '';

  const group = query.get('group')?.toLowerCase() || 'overview';
  const {
    data: apiData,
    isFetching,
    error,
  } = useGetGameAbTestJsonMetricsQuery(
    {
      abTestId: abTestSelected.id,
      country,
      idfa,
      build,
      os,
      from,
      to,
      network,
      ads,
      outliers,
      mediation,
      campaigns,
    },
    { skip: !abTestSelected },
  );

  return (
    <>
      <JsonMetricsFilters sqlQuery={apiData?.query} />
      {isFetching ? (
        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: 200 }}>
          <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
        </div>
      ) : error ? (
        <div className="alert alert-danger d-flex align-items-center">
          <div className=" me-3">
            <InfoIcon />
          </div>
          <div>
            <p className="mb-0">{error?.data?.message || 'Failed metrics fetch.'}</p>
          </div>
        </div>
      ) : (
        apiData && (
          <>
            {!apiData?.summary?.length && !apiData?.dates ? (
              <div className="alert alert-warning d-flex align-items-center">
                <div className=" me-3">
                  <InfoIcon />
                </div>
                <div>
                  <p className="mb-1">No Data available at the moment.</p>
                  <span className="fs-7">
                    Report will be filled a few days after the start of the test. If no data appears after +2 days,
                    check the test settings.
                  </span>
                </div>
              </div>
            ) : (
              <div>
                <div className={`${group !== 'overview' && 'd-none'} d-print-block`}>
                  <OverviewMetrics apiData={apiData} />
                </div>
                <div className={`${group !== 'ads' && 'd-none'} d-print-block`}>
                  <AdsMetrics apiData={apiData} />
                </div>
                <div className={`${group !== 'retention' && 'd-none'} d-print-block`}>
                  <RetentionMetrics apiData={apiData} />
                </div>
                <div className={`${group !== 'playtime' && 'd-none'} d-print-block`}>
                  <PlayTimeMetrics apiData={apiData} />
                </div>
                <div className={`${group !== 'iap' && 'd-none'} d-print-block`}>
                  <IapMetrics apiData={apiData} />
                </div>
              </div>
            )}
          </>
        )
      )}
    </>
  );
}

export default JsonMetricsContainer;
