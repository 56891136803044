import { Alert } from 'react-bootstrap';
import Loader from '../../../../Components/Loader';

const FbAppCheckView = ({ fbApp, fbAppError, isLoading = false }) => {
  if (isLoading) {
    <div className="d-flex justify-content-center">
      <Loader parentStyle="me-2" size={150} color="#3F96C7" />
    </div>;
  }
  return (
    <>
      {fbAppError && (
        <Alert variant={'danger'} className="mt-3 mb-0">
          Application not found. Make sure you created a consumer application and switched to live mode.
        </Alert>
      )}
      {fbApp && (
        <>
          <Alert variant={'info'} className="mt-3">
            <div>
              <b>Name:</b> {fbApp.name}
            </div>
            <div>
              <b>Supported Platforms:</b> {fbApp.supported_platforms.join(',')}
            </div>
            <div>
              <b>Store URLs</b>
              <ul className="mb-2">
                {Object.entries(fbApp.object_store_urls).map(([key, value]) => {
                  if (key === 'instant_game') return null;
                  return (
                    <li key={key}>
                      <a target="_blank" rel="noreferrer" href={value}>
                        {key}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div>
              <b>Authorized Ad Accounts</b>
              <ul className="mb-0">
                {fbApp.authorized_adaccounts.map((acc) => {
                  return (
                    <li key={acc.id}>
                      {acc.name} ({acc.id})
                    </li>
                  );
                })}
              </ul>
            </div>
          </Alert>
          Please save if information above is correct.
        </>
      )}
    </>
  );
};

export default FbAppCheckView;
