import { configureStore } from '@reduxjs/toolkit';
import globalReducer from './slices/global';
import userReducer from './slices/user';
import abTestsReducer from './slices/abTests';
import gameConfigReducer from './slices/gameConfigSlice';

import { userApi } from './api/userApi';
import { studioApi } from './api/studioApi';
import { abTestsApi } from './api/abTestsApi';
import { googleDriveApi } from './api/googleDriveApi';
import { appMagikApi } from './api/appMagikApi';
import { openAiApi } from './api/openAiApi';
import { analyticsApi } from './api/analyticsApi';
import { googleCloudApi } from './api/googleCloudApi';
import { sendEmailApi } from './api/sendEmailApi';
import { aiAgentApi } from './api/aiAgentApi';
import { facebookApi } from './api/fbAdsApi';
import { fghubApi } from './api/fghubApi';

export const store = configureStore({
  devTools: process.env.NODE_ENV === 'development',
  reducer: {
    [userApi.reducerPath]: userApi.reducer,
    [studioApi.reducerPath]: studioApi.reducer,
    [abTestsApi.reducerPath]: abTestsApi.reducer,
    [googleDriveApi.reducerPath]: googleDriveApi.reducer,
    [appMagikApi.reducerPath]: appMagikApi.reducer,
    [openAiApi.reducerPath]: openAiApi.reducer,
    [analyticsApi.reducerPath]: analyticsApi.reducer,
    [googleCloudApi.reducerPath]: googleCloudApi.reducer,
    [sendEmailApi.reducerPath]: sendEmailApi.reducer,
    [aiAgentApi.reducerPath]: aiAgentApi.reducer,
    [facebookApi.reducerPath]: facebookApi.reducer,
    [fghubApi.reducerPath]: fghubApi.reducer,

    abTests: abTestsReducer,
    user: userReducer,
    global: globalReducer,
    gameConfig: gameConfigReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      userApi.middleware,
      studioApi.middleware,
      abTestsApi.middleware,
      googleDriveApi.middleware,
      openAiApi.middleware,
      analyticsApi.middleware,
      googleCloudApi.middleware,
      appMagikApi.middleware,
      sendEmailApi.middleware,
      aiAgentApi.middleware,
      facebookApi.middleware,
      fghubApi.middleware,
    ),
});
