import { forwardRef, useImperativeHandle, useState } from 'react';
import Loader from '../../../../Components/Loader';
import { useUpdateStudioMutation } from '../../../../data/api/studioApi';
import GameAnalyticsStudioID from '../../../UserManagement/CreateStudio/GameAnalyticsStudioID';

const SetStudioGA = forwardRef(({ studio, game, handleResult }, ref) => {
  const [gaStudioId, setGaStudioId] = useState(studio?.gaStudioId || '');
  const [updateStudio, { isLoading: isUpdatingStudio }] = useUpdateStudioMutation();

  useImperativeHandle(ref, () => ({
    submit: async () => {
      if (!gaStudioId) {
        return handleResult('Game Analytics Studio ID is required.', null);
      }
      return await updateStudio({
        id: game?.studioId,
        ga_studio_id: gaStudioId,
      })
        .unwrap()
        .then(() => {
          handleResult(null, gaStudioId);
        })
        .catch((err) => {
          handleResult(err?.data?.message || 'Something went wrong', null);
        });
    },
  }));

  if (isUpdatingStudio) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
      </div>
    );
  }

  return (
    <div>
      <GameAnalyticsStudioID
        value={gaStudioId}
        onChange={setGaStudioId}
        studioName={studio?.name || ''}
        label="Select an existing Game Analytics Studio ID or create a new one."
      />
    </div>
  );
});

export default SetStudioGA;
