import { Modal } from 'react-bootstrap';
import moment from 'moment';

const DATE_FORMATTER = (x) => moment(x).format('YYYY-MM-DD HH:mm:ss UTC');
const JSON_FORMATTER = (x) => JSON.stringify(x, null, 2);

export default function LeaderboardDetailsModal({ show, onHide, selectedLB }) {
  if (!selectedLB) return <></>;
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>"{selectedLB.name}" Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {[
            { name: 'ID', key: 'id' },
            { name: 'Name', key: 'name' },
            { name: 'Store', key: 'store' },
            { name: 'Start', key: 'start_time', formatter: DATE_FORMATTER },
            { name: 'End', key: 'end_time', formatter: DATE_FORMATTER },
            { name: 'Frequency In Days', key: 'frequency_in_days' },
            { name: 'Max User Per Bucket', key: 'max_users' },
            { name: 'County', key: 'country' },
            { name: 'Region', key: 'region' },
            { name: 'Guild', key: 'guild_id' },
            { name: 'Feature', key: 'feature' },
            { name: 'Dimension', key: 'bucket_dimension', allValue: 'None' },
            { name: 'User Seniority Buckets', key: 'user_seniority', allValue: '-' },
            { name: 'User Spend Buckets', key: 'user_cumul_spend', allValue: '-' },
            { name: 'Creation Date', key: 'created_at', formatter: DATE_FORMATTER },
            { name: 'Update Date', key: 'updated_at', formatter: DATE_FORMATTER },
            { name: 'Rewards JSON', key: 'rewards', formatter: JSON_FORMATTER, isCode: true },
          ].map((x) => (
            <div className="mb-3">
              <small class="d-block text-muted fs-6">{x.name}</small>
              <pre>
                {['*', '', null].includes(selectedLB[x.key])
                  ? x.allValue || 'All'
                  : x.formatter
                    ? x.formatter(selectedLB[x.key])
                    : selectedLB[x.key]}
              </pre>
            </div>
          ))}
        </div>
        {/* <pre>{JSON.stringify(selectedLB, null, 2)}</pre> */}
      </Modal.Body>
    </Modal>
  );
}
