import moment from 'moment';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { confirmAlert } from '../../../../../Components/ConfirmModal';
import EditIcon from '../../../../../Components/Icons/EditIcon';
import Loader from '../../../../../Components/Loader';
import { useListAdSetsQuery, useUpsertAdSetMutation } from '../../../../../data/api/fbAdsApi';
import ProcessAdCreationModal from '../FbModals/ProcessAdCreationModal';
import SaveAdsetModal from '../FbModals/SaveAdsetModal';

const FbAdSets = ({ state, setState, fetchedVideos }) => {
  let campaign = state?.selectedCampaign;
  const [selectedAdSet, setSelectedAdSet] = useState(null);
  const {
    data: adSets,
    isFetching,
    error: errorFetching,
  } = useListAdSetsQuery({ id: campaign?.id }, { skip: !campaign?.id });
  const handleOpenModal = (adSet) => setSelectedAdSet(adSet);
  const handleCloseModal = () => setSelectedAdSet(null);
  const [upsertAdset] = useUpsertAdSetMutation();
  const [selectedAdSetForUpdate, setSelectedAdSetForUpdate] = useState(null);

  const [duplicating, setDuplicating] = useState({});

  const formatMoney = (cents) => {
    return (cents / 100).toLocaleString('en-US', { style: 'currency', currency: state.client?.currency || 'USD' });
  };

  const showUpdateModal = (adset) => {
    setSelectedAdSetForUpdate(adset);
  };

  if (isFetching) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: 200 }}>
        <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
      </div>
    );
  }

  if (errorFetching) {
    return <h6>{errorFetching?.error || errorFetching?.message || 'Error getting adsets'}</h6>;
  }

  if (!adSets?.length) {
    return <h6>No active adsets found.</h6>;
  }

  const handleDuplicateAdset = async ({ id, ...adsetRest }) => {
    const startTime = moment(adsetRest.start_time).isBefore(moment())
      ? moment().toISOString()
      : moment(adsetRest.start_time).toISOString();
    const timeDiff = moment(adsetRest.end_time).diff(moment(adsetRest?.start_time), 'hours');

    const adSetPayload = {
      ...adsetRest,
      name: `Copy of ${adsetRest?.name}`,
      campaignId: adsetRest?.campaign_id,
      clientId: state?.client?.id,
      start_time: startTime,
      end_time: adsetRest.end_time ? moment(startTime).add(timeDiff, 'hours') : 0,
    };

    setDuplicating({ [id]: true });
    upsertAdset(adSetPayload)
      .unwrap()
      .then((newAdSet) => {
        if (newAdSet.error) {
          let msg = `${newAdSet.error?.errorUserTitle}: ${newAdSet.error?.errorUserMsg}`;
          confirmAlert({
            variant: 'danger',
            title: newAdSet.error?.errorUserTitle ? newAdSet.error?.errorUserTitle : 'Failed to duplicate adset',
            confirmBtn: false,
            cancelText: 'Ok',
            desc: newAdSet.error?.errorUserTitle
              ? msg
              : newAdSet.error.message || 'Failed to duplicate adset. Please try again.',
          }).catch(() => {});
        } else {
          confirmAlert({
            variant: 'success',
            title: 'Successfully Duplicated!',
            confirmBtn: false,
            cancelText: 'Ok',
          }).catch(() => {});
        }
      })
      .catch((error) => {
        console.error('Failed to duplicate adset:', error);
        confirmAlert({
          variant: 'danger',
          title: 'Failed to duplicate adset ',
          confirmBtn: false,
          cancelText: 'Ok',
          desc: error?.message,
        }).catch(() => {});
      })
      .finally(() => {
        setDuplicating({ [id]: false });
      });
  };

  return (
    <>
      <div className="table-responsive">
        <table className="table table-hover align-middle rounded overflow-hidden bg-white tap-n-table text-center">
          <thead>
            <tr>
              <th className="text-start">Name</th>
              <th>Status</th>
              <th>Start Time</th>
              <th>Daily Budget</th>
              <th>Budget Remaining</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {adSets.map((item) => (
              <tr className="cursor-pointer" key={item.id}>
                <td className="text-start">{item.name}</td>
                <td>{item.status}</td>
                <td>{moment(item.start_time).format('ll')}</td>
                <td>{formatMoney(item.daily_budget)}</td>
                <td>{formatMoney(item.budget_remaining)}</td>
                <td>
                  <Button size="sm" onClick={() => handleOpenModal(item)}>
                    Create Ads
                  </Button>
                  <Button
                    size="sm"
                    className="m-1"
                    disabled={duplicating[item?.id]}
                    onClick={() => handleDuplicateAdset(item)}
                  >
                    Duplicate Adset
                  </Button>
                  <Button
                    variant="outline-light"
                    size="sm"
                    className="p-1 text-nowrap"
                    onClick={() => showUpdateModal(item)}
                    id="fb-eedit-modal-open-button"
                  >
                    <EditIcon color="currentColor" size={18} />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {selectedAdSet && (
        <ProcessAdCreationModal
          state={state}
          adset={selectedAdSet}
          setState={setState}
          handleClose={handleCloseModal}
          fetchedVideos={fetchedVideos}
        />
      )}
      {selectedAdSetForUpdate && (
        <SaveAdsetModal
          closeModal={() => setSelectedAdSetForUpdate(null)}
          campaign={state.selectedCampaign}
          client={state.client}
          adset={selectedAdSetForUpdate}
          onSuccess={(adSet) => {
            setState({
              createAdSet: [...(state.createAdSet || []), adSet],
              hasChanged: true,
            });
          }}
        />
      )}
    </>
  );
};

export default FbAdSets;
