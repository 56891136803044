import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Alert, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useUpdateGameAdjustAppIdMutation } from '../../../../data/api/studioApi';

const SetAdjustToken = forwardRef(({ studio, game, handleResult }, ref) => {
  const [adjustAppToken, setAdjustAppToken] = useState(game?.adjustAppToken || '');
  const [createNewAdjustApp, setCreateNewAdjustApp] = useState(false);
  const [updateGameAdjustAppId] = useUpdateGameAdjustAppIdMutation();

  useImperativeHandle(ref, () => ({
    submit: async () => {
      await updateGameAdjustAppId({
        app_id: game.appId,
        adjust_app_token: createNewAdjustApp ? null : adjustAppToken,
        studio_id: studio.studioId,
      })
        .unwrap()
        .then(() => {
          handleResult(null, true);
        })
        .catch((err) => {
          handleResult(err?.data?.message || 'Something went wrong', null);
        });
    },
  }));

  return (
    <>
      <Form.Group controlId="adjustAppToken">
        <Form.Label className="text-black">Enter Adjust app token if you have one</Form.Label>
        <Alert variant="warning" className="fs-7 p-2">
          If you already created an app on Adjust, you must also link Facebook partner manually.
        </Alert>
        <Form.Control
          type="text"
          placeholder="Adjust App Token"
          value={adjustAppToken}
          onChange={(e) => setAdjustAppToken(e.target.value)}
          disabled={createNewAdjustApp}
        />
      </Form.Group>
      <div className="text-black w-100 text-center my-3 fs-5">OR</div>
      <Form.Group controlId="createNewAdjustApp" className="mt-2 d-flex justify-content-center">
        <OverlayTrigger
          placement="top"
          trigger={game?.adjustAppToken ? ['hover', 'focus'] : []} // show tooltip only when disabled
          overlay={<Tooltip id="adjust-app-tooltip">There is already an Adjust app for this game.</Tooltip>}
        >
          <div>
            <Form.Check
              type="checkbox"
              checked={createNewAdjustApp}
              label="Create a new Adjust App"
              onChange={(e) => {
                setCreateNewAdjustApp(e.target.checked);
              }}
              disabled={game?.adjustAppToken}
            />
          </div>
        </OverlayTrigger>
      </Form.Group>
    </>
  );
});

export default SetAdjustToken;
