import { useState } from 'react';
import { Modal, Alert } from 'react-bootstrap';
import { Form, Button, Col, Row } from 'react-bootstrap';
import { useSetLeaderboardRewardsMutation } from '../../../data/api/fghubApi';

export default function RewardsModal({ show, onHide, selectedLB }) {
  const [setLeaderboardRewards, { isLoading }] = useSetLeaderboardRewardsMutation();
  const [error, setError] = useState(null);
  const [rewards, setRewards] = useState([]);

  const handleChange = (e) => {
    setError(null);
    try {
      const val = JSON.parse(e.target.value);
      setRewards(val);
    } catch (e) {
      setError('Invalid JSON.');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(null);

    setLeaderboardRewards({
      lb_id: selectedLB.id,
      data: rewards,
    })
      .unwrap()
      .then(onHide)
      .catch((error) => {
        setError(error.data.errors.map((x) => `${x.path}: ${x.msg}`));
      });
  };
  if (!selectedLB) return <></>;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Set "{selectedLB.name}" Rewards</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={12} className="mb-3">
              <Form.Group controlId="rewards">
                <Form.Label>Rewards JSON</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={15}
                  style={{ fontFamily: 'monospace' }}
                  name="rewards"
                  defaultValue={JSON.stringify(selectedLB.rewards, null, 2)}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>

            <Col md={12} className="mb-3">
              {error && (
                <Alert variant="danger">
                  Failed to update rewards: {Array.isArray(error) ? error.map((x) => <li>{x}</li>) : error}
                </Alert>
              )}
            </Col>
            <Col md={12} className="mb-3 text-end">
              <Button variant="primary" type="submit" disabled={isLoading}>
                {isLoading ? 'Saving...' : 'Save'}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
