import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useGetApplicationMutation } from '../../../../data/api/fbAdsApi';
import { useUpdateDraftGameMutation } from '../../../../data/api/studioApi';
import { useDebounce } from '../../../../utils/useDebounce';
import FbAppCheckView from '../../common/views/FbAppCheckView';

const SetFbAppID = forwardRef(({ studio, game, handleResult }, ref) => {
  const [fbAppId, setFbAppId] = useState(game?.fbAppId || '');
  const [updateDraftGame] = useUpdateDraftGameMutation();
  const [fetchFacebookApp, { data: fbApp, isLoading: isLoadingFbApp, error: fbAppError }] = useGetApplicationMutation();

  const debouncedFbAppId = useDebounce(fbAppId, 1000);
  useEffect(() => {
    if (!debouncedFbAppId) return;
    fetchFacebookApp(debouncedFbAppId);
  }, [debouncedFbAppId, fetchFacebookApp]);

  useImperativeHandle(ref, () => ({
    submit: async () => {
      if (!fbAppId) {
        return handleResult('Facebook App ID is required.', null);
      }
      return await updateDraftGame({
        id: game?.id,
        studio_id: studio?.studioId,
        fb_app_id: fbAppId,
      })
        .unwrap()
        .then(() => {
          handleResult(null, fbAppId);
        })
        .catch((err) => {
          handleResult(err?.data?.message || 'Something went wrong', null);
        });
    },
  }));

  return (
    <div>
      <Form.Group controlId="fbAppId">
        <Form.Label className="text-black">Enter Facebook App ID</Form.Label>
        <Form.Control
          type="number"
          placeholder="Facebook App ID"
          value={fbAppId}
          onChange={(e) => setFbAppId(e.target.value)}
        />
        <FbAppCheckView fbApp={fbApp} fbAppError={fbAppError} isLoading={isLoadingFbApp} />
      </Form.Group>
    </div>
  );
});

export default SetFbAppID;
