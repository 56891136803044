import fileDownload from 'js-file-download';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import DownloadArrow from '../../../../Components/Icons/DownloadArrow';
import InfoIcon from '../../../../Components/Icons/InfoIcon';
import Loader from '../../../../Components/Loader';
import { R_SDK } from '../../../../constants/routes';
import { CloudGetFile } from '../../../../data/api/Querys';

const SDKView = forwardRef(({ handleResult }, ref) => {
  const [downloadLoading, setDownloadLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    submit: async () => {
      handleResult(null, true);
    },
  }));

  const handleDownloadSDK = async (e) => {
    e.preventDefault();
    const pathNameSDK = 'tapnation-unity-sdk/v2_7_Max/FunGamesSdk_2_7.unitypackage.gz';
    try {
      setDownloadLoading(true);
      const result = await CloudGetFile(pathNameSDK);
      fileDownload(result.data, 'FunGamesSdk-2-7.unitypackage.gz');
    } catch (error) {
      console.error('Error downloading SDK:', error);
    } finally {
      setDownloadLoading(false);
    }
  };

  return (
    <div>
      <div className="alert alert-warning align-items-center fs-7" role="alert">
        <InfoIcon className="me-2" />
        Please read the SDK User Guide to complete these steps:
        <ol className="mb-0">
          <li>Publish the app on the App Store and/or Google Play Store</li>
          <li>Integrate Adjust SDK</li>
          <li>Integrate GA SDK</li>
        </ol>
      </div>

      <div className="d-flex align-items-center">
        <NavLink to={R_SDK} target="_blank" className="btn btn-secondary me-1 flex-grow-1">
          SDK User Guide
        </NavLink>

        <div className="ms-1 d-flex flex-grow-1 justify-content-center ">
          <Button
            className="w-100 text-center download-sdk d-flex justify-content-center"
            onClick={handleDownloadSDK}
            disabled={downloadLoading}
          >
            {downloadLoading ? <Loader parentStyle="loader" size={20} color={'#3F96C7'} /> : <DownloadArrow />}
            <span className="ms-2">{downloadLoading ? 'Downloading...' : 'Download SDK'}</span>
          </Button>
        </div>
      </div>
    </div>
  );
});

export default SDKView;
