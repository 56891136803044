import moment from 'moment';
import React, { useRef, useState } from 'react';
import { Button, Overlay, Popover } from 'react-bootstrap';
import { DateRange } from 'react-date-range';
import Loader from '../../../../../Components/Loader';
import { useListStatsQuery } from '../../../../../data/api/fbAdsApi';

const FbStats = ({ campaign, client }) => {
  const [isLifetime, setIsLifetime] = useState(true);
  const [showDateSelector, setShowDateSelector] = useState(false);
  const [selection, setSelection] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const target = useRef(null);
  const dayFormat = (x) => moment(x).format('YYYY-MM-DD');

  const { data: stats, isFetching } = useListStatsQuery(
    {
      id: campaign?.id,
      params: {
        level: 'adset',
        fields: 'adset_id,adset_name,spend,impressions,reach,actions',
        ...(isLifetime
          ? { date_preset: 'maximum' }
          : {
              time_range: {
                since: moment(selection.startDate).format('YYYY-MM-DD'),
                until: moment(selection.endDate).format('YYYY-MM-DD'),
              },
            }),
      },
    },
    { skip: !campaign?.id || showDateSelector },
  );
  const formatDecimal = (number) => {
    if (isNaN(number)) return '-';
    return Number(number).toFixed(2);
  };
  const formatMoney = (dollars) => {
    if (isNaN(dollars)) return '-';
    return Number(dollars).toLocaleString('en-US', { style: 'currency', currency: client?.currency || 'USD' });
  };

  const fields = [
    {
      field: 'adset',
      name: 'Adset',
      calc: (row) => {
        if (row.adset_name === undefined) return 'TOTAL';
        return `${row['adset_name']}`;
      },
      className: 'text-start w-25',
    },
    {
      field: 'spend',
      name: 'Spend',
      format: formatMoney,
    },
    {
      field: 'actions.mobile_app_install',
      name: 'Installs',
    },
    {
      field: 'cost_per.mobile_app_install',
      name: 'CPI',
      calc: (row) => {
        if (!row['actions.mobile_app_install']) return '-';
        return row['spend'] / row['actions.mobile_app_install'];
      },
      format: formatMoney,
    },
    {
      field: 'per_mile.mobile_app_install',
      name: 'IPM',
      calc: (row) => {
        return (row['actions.mobile_app_install'] * 1000) / row['impressions'];
      },
      format: formatDecimal,
    },
    {
      field: 'impressions',
      name: 'Impressions',
    },
    {
      field: 'reach',
      name: 'Reach',
    },
  ];

  if (isFetching) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: 200 }}>
        <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
      </div>
    );
  }

  return (
    <>
      <Button ref={target} onClick={() => setShowDateSelector(!showDateSelector)} className="ms-3">
        {isLifetime ? (
          'Date Range: All'
        ) : (
          <>
            {dayFormat(selection.startDate)} to {dayFormat(selection.endDate)}
          </>
        )}
      </Button>

      <Overlay target={target.current} show={showDateSelector} placement="right">
        <Popover style={{ maxWidth: 'none' }}>
          <Popover.Body className="m-0 p-0">
            <DateRange ranges={[selection]} onChange={(ranges) => setSelection(ranges.selection)} />
            <div className="d-flex justify-content-between p-2 border-top">
              <Button
                variant="primary"
                size="sm"
                onClick={() => {
                  setIsLifetime(true);
                  setShowDateSelector(false);
                }}
              >
                Clear
              </Button>
              <Button
                variant="primary"
                size="sm"
                onClick={() => {
                  setIsLifetime(false);
                  setShowDateSelector(false);
                }}
              >
                Done
              </Button>
            </div>
          </Popover.Body>
        </Popover>
      </Overlay>
      <div className="table-responsive m-3">
        {stats?.data.length === 0 ? (
          <div className="d-flex justify-content-center align-items-center">No data.</div>
        ) : (
          <table className="table table-hover align-middle rounded overflow-hidden bg-white tap-n-table text-end">
            <thead>
              <tr>
                {fields.map((x) => (
                  <td key={x.field} className={x.className}>
                    {x.name}
                  </td>
                ))}
              </tr>
            </thead>
            <tbody>
              {stats?.data?.map((row, i) => (
                <tr key={i}>
                  {fields.map((x) => {
                    let val = row[x.field];
                    if (x.calc) val = x.calc(row);
                    if (x.format) val = x.format(val);
                    return (
                      <td key={x.field} className={x.className}>
                        {val}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                {fields.map((x) => {
                  let val = stats.summary[x.field];
                  if (x.calc) val = x.calc(stats.summary);
                  if (x.format) val = x.format(val);
                  return (
                    <td key={x.field} className={x.className}>
                      {val}
                    </td>
                  );
                })}
              </tr>
            </tfoot>
          </table>
        )}
      </div>
    </>
  );
};

export default FbStats;
