import * as Joi from 'joi';

export const InfosViewSchema = Joi.object({
  app_name: Joi.string().required().trim().messages({ 'string.empty': 'Game Name is required' }),
  store: Joi.string().required().trim().messages({ 'string.empty': 'Store is required' }),
  bundle_id: Joi.string().required().pattern(new RegExp(/^\S+$/)).messages({
    'string.empty': 'Bundle ID is required',
    'string.pattern.base': 'In Bundle ID spacing is not allowed',
  }),
  app_id: Joi.string().required().trim().pattern(new RegExp(/^\S+$/)).messages({
    'string.empty': 'Store ID is required',
    'string.pattern.base': 'In Store ID spacing is not allowed',
  }),
  bizdev_email: Joi.string()
    .trim()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      'object.unknown': 'Contact Email is required',
      'string.empty': 'Contact Email is required',
      'any.required': 'Contact Email is required',
      'string.email': 'Invalid Email',
    }),
  category: Joi.string().trim().required().messages({ 'string.empty': 'Category is required' }),
  studio_id: Joi.string().required().trim().messages({ 'string.empty': 'Studio is required' }),
  tags: Joi.array().min(1).required().messages({
    'array.min': 'At least one tag is required',
    'any.required': 'Tags are required',
  }),
}).options({ abortEarly: false, allowUnknown: true });

export default InfosViewSchema;
