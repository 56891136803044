import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { R_LOGIN } from '../constants/routes';
import { setTopBarTitle } from '../data/slices/global';
import { userSelector } from '../data/slices/user';
import SideMenuLayout from '../Layouts/SideMenuLayout/SideMenuLayout';

const GuardedRoute = ({ permission, component: Component, layout: Layout, title, back, search, ...rest }) => {
  const user = useSelector(userSelector);
  const hasPermission = { permission };
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (title) {
      document.title = 'TapNation - ' + title;
    }
    dispatch(
      setTopBarTitle({
        title,
        back,
        search,
      }),
    );
  }, [title, back, search, dispatch]);

  return (
    <Route
      {...rest}
      render={(props) => {
        return user?.id && (permission ? hasPermission : true) ? (
          <Layout {...rest}>
            <Component {...rest} {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: R_LOGIN,
              state: { redirectUrl: location?.pathname + location?.search || null },
            }}
          />
        );
      }}
    />
  );
};
GuardedRoute.defaultProps = {
  layout: SideMenuLayout,
  admin: false,
  footer: true,
  title: '',
  back: '',
  search: false,
};
export default GuardedRoute;
