import { useState } from 'react';
import { Modal, Alert } from 'react-bootstrap';
import { Form, Button, Col, Row } from 'react-bootstrap';
import SearchableDropdown from '../../../Components/SearchableDropdown';
import { abTestCountries } from '../../../constants/abTestCountries';
import { useCreateLeaderboardMutation } from '../../../data/api/fghubApi';

export default function CreateLeaderboardModal({ show, onHide, game }) {
  const [createLeaderboard, { isLoading }] = useCreateLeaderboardMutation();
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    name: '',
    store: 'apple',
    start_time: new Date().toISOString().slice(0, 19),
    end_time: '',
    frequency_in_days: '',
    max_users: 100,
    country: '',
    region: '',
    guild_id: '',
    feature: '',
    user_seniority: '',
    user_cumul_spend: '',
    bucket_dimension: '',
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(null);

    const data = { ...formData };
    if (data.bucket_dimension === '') {
      delete data.bucket_dimension;
    }
    data.start_time = new Date(data.start_time).getTime();
    data.end_time = new Date(data.end_time).getTime();

    createLeaderboard({
      bundle_id: game.bundleId,
      data: data,
    })
      .unwrap()
      .then(onHide)
      .catch((error) => {
        if (error.data?.details?.includes('bundle_id must be unique')) {
          setError('Failed: Duplicate leaderboard');
        } else if (error.data.errors) {
          setError('Failed to create: ' + error.data.errors.map((x) => `${x.path}: ${x.msg}`).join(', '));
        } else {
          setError('Failed to create leaderboard: ' + error.data);
        }
      });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Create Leaderboard Config</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={12} className="mb-3">
              <Form.Group controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" name="name" value={formData.name} onChange={handleChange} required />
              </Form.Group>
            </Col>

            <Col md={12} className="mb-3">
              <Form.Group controlId="store">
                <Form.Label>Store</Form.Label>
                <Form.Control as="select" name="store" value={formData.store} onChange={handleChange} required>
                  <option value="apple">Apple</option>
                  <option value="google">Google</option>
                  <option value="amazon">Amazon</option>
                </Form.Control>
              </Form.Group>
            </Col>

            <Col md={12} className="mb-3">
              <Form.Group controlId="start_time">
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="datetime-local"
                  name="start_time"
                  value={formData.start_time}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>

            <Col md={12} className="mb-3">
              <Form.Group controlId="end_time">
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  type="datetime-local"
                  name="end_time"
                  value={formData.end_time}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>

            <Col md={12} className="mb-3">
              <Form.Group controlId="frequency_in_days">
                <Form.Label>Frequency In Days</Form.Label>
                <Form.Control
                  type="number"
                  name="frequency_in_days"
                  value={formData.frequency_in_days}
                  onChange={handleChange}
                  min={1}
                  required
                />
              </Form.Group>
            </Col>

            <Col md={12} className="mb-3">
              <Form.Group controlId="max_users">
                <Form.Label>Max Users Per Bucket</Form.Label>
                <Form.Control
                  type="number"
                  name="max_users"
                  value={formData.max_users}
                  onChange={handleChange}
                  min={1}
                  required
                />
              </Form.Group>
            </Col>

            <Col md={12} className="mb-3">
              <Form.Group controlId="country">
                <Form.Label>Country</Form.Label>
                <SearchableDropdown
                  multiple
                  collapseGroup
                  onSelect={(data) => {
                    setFormData({
                      ...formData,
                      country: data.join(','),
                    });
                  }}
                  selected={formData.country ? formData.country.split(',') : []}
                  options={abTestCountries}
                  disableSearch
                />
              </Form.Group>
            </Col>

            <Col md={12} className="mb-3">
              <Form.Group controlId="region">
                <Form.Label>Region</Form.Label>
                <SearchableDropdown
                  multiple
                  collapseGroup
                  onSelect={(data) => {
                    setFormData({
                      ...formData,
                      region: data.join(','),
                    });
                  }}
                  selected={formData.region ? formData.region.split(',') : []}
                  options={['AME', 'EUR', 'AFR', 'ASI', 'OCE'].map((x) => ({ label: x, value: x }))}
                  disableSearch
                />
              </Form.Group>
            </Col>

            <Col md={12} className="mb-3">
              <Form.Group controlId="guild_id">
                <Form.Label>Guild ID</Form.Label>
                <Form.Control
                  type="number"
                  name="guild_id"
                  value={formData.guild_id}
                  onChange={handleChange}
                  placeholder="All"
                />
              </Form.Group>
            </Col>

            <Col md={12} className="mb-3">
              <Form.Group controlId="feature">
                <Form.Label>Feature</Form.Label>
                <Form.Control
                  type="text"
                  name="feature"
                  value={formData.feature}
                  onChange={handleChange}
                  placeholder="All"
                  pattern="^[a-zA-Z0-9-]+$"
                />
              </Form.Group>
            </Col>

            <Col md={12} className="mb-3">
              <Form.Group controlId="bucket_dimension">
                <Form.Label>Bucket Dimension</Form.Label>
                <Form.Control
                  as="select"
                  name="bucket_dimension"
                  value={formData.bucket_dimension}
                  onChange={handleChange}
                >
                  <option value="">None</option>
                  <option value="USER_SENIORITY">User Seniority</option>
                  <option value="USER_SPEND">User Spend</option>
                  <option value="COUNTRY">Country</option>
                  <option value="REGION">Region</option>
                </Form.Control>
              </Form.Group>
            </Col>

            <Col md={12} className="mb-3">
              <Form.Group controlId="user_seniority">
                <Form.Label>User Seniority Ranges</Form.Label>
                <Form.Control
                  type="text"
                  name="user_seniority"
                  value={formData.user_seniority}
                  onChange={handleChange}
                  placeholder="0-10,11-20,20-100"
                  pattern="^\d+-\d+(,\d+-\d+)*$"
                />
              </Form.Group>
            </Col>

            <Col md={12} className="mb-3">
              <Form.Group controlId="user_cumul_spend">
                <Form.Label>User Spend Ranges </Form.Label>
                <Form.Control
                  type="text"
                  name="user_cumul_spend"
                  value={formData.user_cumul_spend}
                  onChange={handleChange}
                  placeholder="0-10,11-20,20-100"
                  pattern="^\d+-\d+(,\d+-\d+)*$"
                />
              </Form.Group>
            </Col>

            <Col md={12} className="mb-3">
              {error && <Alert variant="danger">{error}</Alert>}
            </Col>
            <Col md={12} className="mb-3 text-end">
              <Button variant="primary" type="submit" disabled={isLoading}>
                {isLoading ? 'Creating...' : 'Create'}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
