import { Form } from 'react-bootstrap';
import Loader from '../../../../../Components/Loader';
import SearchableDropdown from '../../../../../Components/SearchableDropdown';
import { useListPagesQuery } from '../../../../../data/api/fbAdsApi';

const ALLOWED_PAGES = ['103574988072118', '111207553981966'];

const FbPagesList = ({ value, onChange }) => {
  const { data: pages, isLoading } = useListPagesQuery({});
  const filteredPages = pages?.filter((page) => ALLOWED_PAGES.includes(page.id));

  const handleChange = (selectedIds) => {
    const selectedValue = selectedIds[0];
    const selectedPage = pages?.find((page) => page.id === selectedValue);
    const pageName = selectedPage ? selectedPage.name : '';
    onChange({ pageId: selectedValue, pageName });
  };

  return (
    <Form.Group controlId="clientSelect" className="d-flex align-items-center gap-2">
      {isLoading ? (
        <Loader parentStyle="loader" size={23} color={'#3F96C7'} />
      ) : (
        <SearchableDropdown
          writeSelected={true}
          allowClear={false}
          selected={[value]}
          onSelect={handleChange}
          options={filteredPages?.map((page) => ({ label: page.name, value: page.id }))}
          placeholder={'Select Facebook Page'}
        />
      )}
    </Form.Group>
  );
};

export default FbPagesList;
