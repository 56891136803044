import { Button, Form, Modal } from 'react-bootstrap';
import Loader from '../../../../../Components/Loader';
import React, { useState } from 'react';
import { useUpdateGAGameIdMutation } from '../../../../../data/api/studioApi';
import { confirmAlert } from '../../../../../Components/ConfirmModal';
import EditIcon from '../../../../../Components/Icons/EditIcon';

const UpdateGameAnalyticsGameIdModal = ({ gameId, studioId, gaGameId }) => {
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState('');
  const [newGameId, setNewGameId] = useState(gaGameId);

  const [updateGAGameId, { isLoading }] = useUpdateGAGameIdMutation();
  const handleSubmit = async () => {
    try {
      await updateGAGameId({
        gameId,
        studioId,
        gaId: newGameId,
      }).unwrap();
      confirmAlert({
        variant: 'success',
        title: 'GA GameID updated successfully',
        confirmBtn: false,
        cancelText: 'Ok',
      }).catch(() => {});
      setShowModal(false);
      setError('');
    } catch (err) {
      confirmAlert({
        variant: err?.status === 409 ? 'success' : 'danger',
        title: err?.status === 409 ? 'GA GameID updated successfully' : 'Failed to update ga game id',
        desc: err?.data?.message ? err?.data?.message : err?.message ? err.message : undefined,
        confirmBtn: false,
        cancelText: 'Ok',
      }).catch(() => {});
    }
  };
  const handleTextChange = (e) => {
    const text = e.target.value;
    setNewGameId(Number(text));
    setError('');
  };

  return (
    <>
      <Button
        variant="outline-light"
        size="sm"
        className="p-1 ms-1 text-nowrap"
        onClick={(e) => {
          setShowModal(true);
        }}
        id="emai-modal-open-button"
      >
        <EditIcon color="currentColor" size={18} />
      </Button>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update GA Game ID </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center">
              <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
            </div>
          ) : (
            <>
              <Form.Group className="position-relative me-3 flex-grow-1 flex-basis-0" controlId="newid">
                <Form.Control
                  type="number"
                  placeholder="GA Game ID"
                  value={newGameId}
                  isInvalid={!!error}
                  onChange={(e) => handleTextChange(e)}
                  name="gagameid"
                />
                <Form.Control.Feedback tooltip type="invalid">
                  {error}
                </Form.Control.Feedback>
              </Form.Group>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit} disabled={isLoading}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdateGameAnalyticsGameIdModal;
