import { useCallback, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import DynamicSelect from '../../../../../Components/DynamicSelect';
import Loader from '../../../../../Components/Loader';
import { useGetCampaignQuery, useLazySearchCampaignsQuery } from '../../../../../data/api/fbAdsApi';
import CampaignStatusToggle from '../CampaignStatusToggle';

const CampaignList = ({ state, setState }) => {
  const clientID = state.client?.id;
  const selectedCampaignId = state?.selectedCampaign?.id;
  const [searchCampaigns] = useLazySearchCampaignsQuery();
  const { data: campaignData, isFetching } = useGetCampaignQuery(
    { id: clientID, campaignId: selectedCampaignId },
    { skip: !selectedCampaignId || !clientID },
  );

  const handleSearch = useCallback(
    async (q) => {
      return await searchCampaigns({ id: clientID, q }).unwrap();
    },
    [clientID, searchCampaigns],
  );

  useEffect(() => {
    if (campaignData) {
      setState({ selectedCampaign: campaignData, hasChanged: true });
    }
  }, [campaignData]);

  const handleCampaignSelect = (selectedCampaign) => {
    setState({ selectedCampaign, hasChanged: true });
  };

  if (isFetching) {
    return <Loader parentStyle="loader" size={23} color={'#3F96C7'} />;
  }

  return (
    <Form.Group controlId="campaignSelect" xs={6} className="d-flex align-items-center gap-3">
      <DynamicSelect
        placeholder={'Search Campaign'}
        selected={state.selectedCampaign}
        searchFunction={handleSearch}
        onChange={handleCampaignSelect}
        labelFormatter={(x) => `${x.name} (${x.effective_status})`}
      />
      {state.selectedCampaign && <CampaignStatusToggle campaign={state.selectedCampaign} />}
    </Form.Group>
  );
};

export default CampaignList;
