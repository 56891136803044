import { forwardRef, useImperativeHandle, useState } from 'react';
import { Form } from 'react-bootstrap';
import Loader from '../../../../Components/Loader';
import { useCreateGameAnalyticsGameMutation, useUpdateDraftGameMutation } from '../../../../data/api/studioApi';

const SetGameGA = forwardRef(({ studio, game, handleResult }, ref) => {
  const [gaId, setGaId] = useState(game?.gaId || '');
  const [createNewGa, setCreateNewGa] = useState(false);
  const [createGa, { isLoading: isCreatingGa }] = useCreateGameAnalyticsGameMutation();
  const [updateDraftGame] = useUpdateDraftGameMutation();

  useImperativeHandle(ref, () => ({
    submit: async () => {
      // create new ga game, automatically updates the game
      if (createNewGa) {
        return await createGa({
          id: game.id,
          appName: game.appName,
          os: game.os,
          store: game.store,
          bundleId: game.bundleId,
          studioId: game.studioId,
        })
          .unwrap()
          .then(() => {
            handleResult(null, true);
          })
          .catch((err) => {
            handleResult(err?.data?.message || 'Something went wrong', null);
          });
      }

      // update draft game with existing ga game id
      if (!gaId) {
        return handleResult('Game Analytics Game ID is required.', null);
      }
      return await updateDraftGame({
        id: game?.id,
        studio_id: studio?.studioId,
        ga_id: gaId,
      })
        .unwrap()
        .then(() => {
          handleResult(null, gaId);
        })
        .catch((err) => {
          handleResult(err?.data?.message || 'Something went wrong', null);
        });
    },
  }));

  if (isCreatingGa) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
      </div>
    );
  }

  return (
    <>
      <Form.Group controlId="gaGameId">
        <Form.Label className="text-black">Enter GA Game ID if you have one</Form.Label>
        <Form.Control
          type="number"
          placeholder="GA Game ID"
          value={gaId}
          onChange={(e) => setGaId(e.target.value)}
          disabled={createNewGa}
        />
      </Form.Group>
      <div className="text-black w-100 text-center my-3 fs-5">OR</div>
      <Form.Group controlId="createNewGa" className="mt-2 d-flex justify-content-center">
        <Form.Check
          type="checkbox"
          checked={createNewGa}
          label="Create a new GA Game"
          onChange={(e) => {
            setCreateNewGa(e.target.checked);
          }}
        />
      </Form.Group>
    </>
  );
});

export default SetGameGA;
