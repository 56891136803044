import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form } from 'react-bootstrap';
import Loader from '../../../Components/Loader';
import { useCreateGameAnalyticsStudioMutation, useListGameAnalyticsStudiosQuery } from '../../../data/api/studioApi';

function GameAnalyticsStudioID({ value, onChange, studioName, label = 'Game Analytics Studio ID' }) {
  const [createGameAnalyticsStudio, { isLoading: createLoading, isSuccess, error }] =
    useCreateGameAnalyticsStudioMutation();
  const { data: studios, isLoading: isStudiosLoading } = useListGameAnalyticsStudiosQuery();

  const handleCreateNew = () => {
    createGameAnalyticsStudio({ studioName })
      .unwrap()
      .then((data) => {
        onChange(data.id);
      })
      .catch((error) => {
        console.error('Error creating Game Analytics Studio ID:', error);
      });
  };

  return (
    <Form.Group controlId="gameAnalyticsStudioID">
      <Form.Label className="fs-7">{label}</Form.Label>
      <div className="d-flex justify-content-between">
        {isStudiosLoading ? (
          <div className="w-100 d-flex justify-content-center align-items-center">
            <Loader parentStyle="loader" size={50} color={'#3F96C7'} className="w-100" />
          </div>
        ) : (
          <>
            <div className="w-100">
              <Form.Select
                placeholder="Select Studio"
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                value={value}
                isInvalid={!!error}
              >
                <option value="">Select GA Studio</option>
                {studios
                  ?.filter((el) => !el.isArchived)
                  ?.map((el) => (
                    <option key={el.id} value={el.id}>
                      {el.id} - {el.name}
                    </option>
                  ))}
              </Form.Select>
            </div>
            <Button
              variant="primary"
              size="sm"
              className="text-nowrap"
              onClick={handleCreateNew}
              disabled={createLoading}
            >
              {createLoading ? 'Creating...' : isSuccess ? 'Created' : 'Create New'}
            </Button>
          </>
        )}
      </div>

      {error && (
        <div className="alert alert-danger my-1 p-1 d-flex align-items-center fs-7" role="alert">
          <div>{error?.data?.message || 'An error occurred'}</div>
        </div>
      )}
    </Form.Group>
  );
}

GameAnalyticsStudioID.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  studioName: PropTypes.string.isRequired,
};

export default GameAnalyticsStudioID;
