import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, Link, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import { useUserPermission } from '../../../Components/permissions/UserPermissions';
import {
  R_GAME_ABTESTS,
  R_GAME_CONFIGS,
  R_GAME_FBADS,
  R_GAME_INFO,
  R_GAME_LEADERBOARDS,
  R_GAME_METRICS,
  R_GAMES,
} from '../../../constants/routes';
import { useGetGamesQuery } from '../../../data/api/studioApi';
import { getGameById } from '../../../data/slices/studio';
import EmptyLayout from '../../../Layouts/EmptyLayout';
import GuardedRoute from '../../../Modules/GuardedRoute';
import AbTestCreateBtns from '../../AbTest/CreateAbTestModal/AbTestCreateBtns';
import GameAbtestList from '../../AbTest/GameAbtestList/GameAbtestList';
import Leaderboards from '../Leaderboards/Leaderboards';
import PublishDraftGame from '../PublishDraftGame/PublishDraftGame';
import UpdateDraftGameModal from '../UpdateDraftGame/UpdateDraftGameModal';
import FbAds from './FbCampaigns/FbAds';
import GameInfo from './Info/GameInfo';
import GameMetrics from './Metrics/GameMetrics';

const RedirectView = ({ redirectTo = R_GAME_INFO }) => {
  const history = useHistory();
  const { id } = useParams();
  useEffect(() => {
    history.push(generatePath(redirectTo, { id }));
  }, []);
  return null;
};

function Game() {
  useGetGamesQuery();
  const history = useHistory();
  const { id } = useParams();
  const { pathname } = useLocation();
  const foundGame = useSelector(getGameById(id));
  const isDraft = foundGame?.status === 'DRAFT';

  const showCreateTest =
    !!foundGame && (foundGame.id === Number(id) || foundGame?.ios?.id === Number(id))
      ? foundGame.status !== 'ARCHIVED' || foundGame.ios?.status !== 'ARCHIVED'
      : false;

  const LINKS = [
    {
      path: R_GAME_METRICS,
      text: 'Metrics',
      component: GameMetrics,
    },
    {
      path: R_GAME_INFO,
      text: 'Information',
      component: GameInfo,
      availableInDraft: true,
    },
    {
      path: R_GAME_ABTESTS,
      text: 'A/B Tests',
      component: GameAbtestList,
      props: { disableTopBar: true, type: 'ABTEST' },
    },
    {
      path: R_GAME_CONFIGS,
      text: 'Configs',
      component: GameAbtestList,
      props: { disableTopBar: true, type: 'CONFIG' },
    },
    {
      path: R_GAME_FBADS,
      text: 'Prototype Testing',
      component: FbAds,
      isHidden: !useUserPermission('fb.ads'),
    },
    {
      path: R_GAME_LEADERBOARDS,
      text: 'Leaderboards',
      component: Leaderboards,
    },
    {
      // default route
      component: () => <RedirectView redirectTo={isDraft ? R_GAME_INFO : R_GAME_METRICS} />,
    },
  ];

  return (
    <div className="container-fluid mb-4">
      <div className="row top-bar mb-4 px-md-2 bg-white">
        <div className="col-12 d-flex align-items-center">
          <div className="top-bar-tab-btns">
            {LINKS.map((link, index) => {
              if (link.isHidden) return null;
              const isSelected = pathname === generatePath(link.path, { id });
              const isDisabled = isDraft && !link.availableInDraft;
              return (
                <Link
                  key={index}
                  className={`btn ${isSelected ? 'active' : ''} ${isDisabled ? 'disabled' : ''}`}
                  to={generatePath(link.path, { id })}
                >
                  {link.text}
                </Link>
              );
            })}
          </div>
          <div className="ms-auto d-flex">
            {isDraft ? (
              <>
                <div className="me-3">
                  <PublishDraftGame id={id} />
                </div>
                <UpdateDraftGameModal
                  onDelete={() => {
                    history.push(generatePath(R_GAMES, { filter: 'draft' }));
                  }}
                  id={id}
                />
              </>
            ) : (
              showCreateTest && <AbTestCreateBtns type={pathname?.includes('configs') ? 'CONFIG' : 'ABTEST'} id={id} />
            )}
          </div>
        </div>
      </div>
      <Switch>
        {LINKS.map((link, index) => (
          <GuardedRoute
            key={index}
            title={link.text}
            path={link.path}
            component={
              isDraft && !link.availableInDraft
                ? RedirectView
                : (props) => <link.component {...props} {...(link.props || {})} />
            }
            layout={EmptyLayout}
            back={generatePath(R_GAMES, { filter: null })}
          />
        ))}
        {/* Redirect to the appropriate route based on the game status */}
        <GuardedRoute component={() => <RedirectView redirectTo={isDraft ? R_GAME_INFO : R_GAME_METRICS} />} />
      </Switch>
    </div>
  );
}

export default Game;
