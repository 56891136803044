import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { hmac256 } from '../../utils/common';

const fghubBaseUrl = process.env.REACT_APP_FGHUB_API;

export const fghubApi = createApi({
  reducerPath: 'fghubApi',
  baseQuery: fetchBaseQuery({
    baseUrl: fghubBaseUrl,
    prepareHeaders: (headers) => {
      headers.set('Accept', 'application/json');
      return headers;
    },
  }),
  tagTypes: ['leaderboards'],
  endpoints: (builder) => ({
    listLeaderboards: builder.query({
      query: (bundle_id) => {
        const path = `/${bundle_id}/leaderboards`;
        return {
          url: path,
          headers: { Authorization: hmac256(fghubBaseUrl + path) },
        };
      },
      providesTags: (result) => (result ? [{ type: 'leaderboards' }] : []),
    }),
    createLeaderboard: builder.mutation({
      query: ({ bundle_id, data }) => ({
        url: `/${bundle_id}/leaderboards`,
        method: 'POST',
        body: data,
        headers: { Authorization: hmac256(data) },
      }),
      invalidatesTags: (result, error, args) => (result ? [{ type: 'leaderboards' }] : []),
    }),
    setLeaderboardRewards: builder.mutation({
      query: ({ lb_id, data }) => ({
        url: `/leaderboards/${lb_id}/rewards`,
        method: 'POST',
        body: data,
        headers: { Authorization: hmac256(data) },
      }),
      invalidatesTags: (result, error, args) => (result ? [{ type: 'leaderboards' }] : []),
    }),
  }),
});

export const { useListLeaderboardsQuery, useCreateLeaderboardMutation, useSetLeaderboardRewardsMutation } = fghubApi;
