import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getGameById } from '../../../data/slices/studio';
import Loader from '../../../Components/Loader';
import { useListLeaderboardsQuery } from '../../../data/api/fghubApi';
import GameInfoCard from '../../../Components/GameInfoCard';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import { useState } from 'react';
import LeaderboardDetailsModal from './Details';
import CreateLeaderboardModal from './Create';
import RewardsModal from './Rewards';

const DATE_FORMATTER = (x) => moment(x).format('YYYY-MM-DD HH:mm:ss UTC');

function Leaderboards() {
  const { id } = useParams();
  const foundGame = useSelector(getGameById(id));
  const { data: lbs, isLoading } = useListLeaderboardsQuery(foundGame?.bundleId, { skip: !foundGame });
  const [showCreate, setShowCreate] = useState(false);
  const [showRewards, setShowRewards] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedLB, setSelectedLB] = useState(null);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '50vh' }}>
        <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
      </div>
    );
  }

  return (
    <div className="d-flex flex-column gap-3">
      <div className="d-flex align-items-start">
        <div className="col-sm-12 col-md-6 col-xl-4">
          <GameInfoCard id={id} />
        </div>
        <Button
          variant="success"
          className="ms-auto mt-auto"
          onClick={() => {
            setShowCreate(true);
          }}
        >
          Create Leaderboard Config
        </Button>
      </div>
      <div className="table-responsive">
        <table className="table table-hover align-middle rounded overflow-hidden bg-white tap-n-table text-center">
          <thead>
            <tr>
              <th>LB Config ID</th>
              <th>Name</th>
              <th>Store</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {lbs?.map((item) => (
              <tr
                className="cursor-pointer"
                key={item.id}
                onClick={() => {
                  setSelectedLB(item);
                  setShowDetails(true);
                }}
              >
                <td>{item.id}</td>
                <td>{item.name}</td>
                <td>{item.store}</td>
                <td>{DATE_FORMATTER(item.start_time)}</td>
                <td>{DATE_FORMATTER(item.end_time)}</td>
                <td>
                  <Button
                    className="me-2"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedLB(item);
                      setShowRewards(true);
                    }}
                  >
                    Set Rewards
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <LeaderboardDetailsModal
        show={showDetails}
        selectedLB={selectedLB}
        onHide={() => {
          setShowDetails(false);
          setSelectedLB(null);
        }}
      />
      <CreateLeaderboardModal show={showCreate} onHide={() => setShowCreate(false)} game={foundGame} />
      <RewardsModal
        show={showRewards}
        selectedLB={selectedLB}
        onHide={() => {
          setShowRewards(false);
          setSelectedLB(null);
        }}
      />
    </div>
  );
}

export default Leaderboards;
