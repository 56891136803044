import { Button, Form, OverlayTrigger, Popover } from 'react-bootstrap';

const TagsDropdown = ({ values, options, onChange, placeholder = 'Select Tags', placement = 'auto', ...rest }) => {
  const popover = (
    <Popover style={{ maxWidth: '400px' }} className="shadow">
      <Popover.Body className={'d-flex flex-wrap '}>
        {options.map((option) => (
          <Button
            key={option}
            size="sm"
            variant={values.includes(option) ? 'success' : 'light'}
            onClick={() => onChange(option)}
            className="fs-6  m-1 "
          >
            {option}
          </Button>
        ))}
      </Popover.Body>
    </Popover>
  );

  return (
    <div>
      <OverlayTrigger rootClose rootCloseEvent="mousedown" trigger="click" placement={placement} overlay={popover}>
        <Form.Control
          type="text"
          readOnly={true}
          placeholder={placeholder}
          value={values.length ? values.join(', ') : ''}
          className="form-control text-muted cursor-pointer"
          isInvalid={!!rest.errors}
        />
      </OverlayTrigger>
      <Form.Control.Feedback tooltip type="invalid">
        {rest.errors}
      </Form.Control.Feedback>
    </div>
  );
};

export default TagsDropdown;
