import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import AddIcon from '../../../Components/Icons/AddIcon';
import Loader from '../../../Components/Loader';
import { Restricted, useUserPermission } from '../../../Components/permissions/UserPermissions';
import { R_GAME_INFO } from '../../../constants/routes';
import { GetLogo } from '../../../data/api/Querys';
import { studioApi, useCreateDraftGameMutation } from '../../../data/api/studioApi';
import { studioSelectedSelector, userSelector } from '../../../data/slices/user';
import { camelToSnakeCaseObj } from '../../../utils/converters';
import { useSetState } from '../../../utils/customHooks';
import { formatJoiErrorsArray } from '../../../utils/validation';
import infosViewSchema from '../common/schemas/infosViewSchema';
import InfosView from '../common/views/InfosView';

const CreateGameModal = () => {
  const history = useHistory();
  const user = useSelector(userSelector);
  const studioSelected = useSelector(studioSelectedSelector);
  const selectStudioPermission = useUserPermission('select.studio');
  const dispatch = useDispatch();
  const [createDraftGame] = useCreateDraftGameMutation();

  const [showForm, setShowForm] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const infosDefaultVal = {
    studioId: selectStudioPermission
      ? studioSelected?.studioId || ''
      : user?.studios && Object.keys(user?.studios)?.[0],
    appName: '',
    bundleId: '',
    store: 'google',
    appId: '',
    bizdevEmail: '',
    category: '',
    tags: [],
    logoUrl: '',
    advertisingSdk: '',
  };
  const [infos, setInfos] = useSetState(infosDefaultVal);
  useEffect(() => {
    if (Object.keys(errors).length) {
      setErrors({});
    }
  }, [infos]);

  const handleNext = async () => {
    setLoading(true);
    let tmp = infos;
    if (tmp.store === 'google') {
      tmp.appId = infos.bundleId;
    }
    tmp.os = tmp.store === 'apple' ? 'ios' : 'android';
    const { error } = infosViewSchema.validate(camelToSnakeCaseObj(tmp));
    if (error) {
      setLoading(false);
      setErrors(formatJoiErrorsArray(error.details));
      return false;
    }
    setInfos(tmp);
    await getLogoFromStore(tmp.appId);

    createDraftGame({ ...camelToSnakeCaseObj(infos) })
      .unwrap()
      .then((response) => {
        dispatch(studioApi.util.invalidateTags(['games']));
        history.push(generatePath(R_GAME_INFO, { id: response?.id }));
        handleClose();
      })
      .catch((error) => {
        setLoading(false);
        setErrors({
          error: error?.data?.message || 'Something went wrong',
        });
      });
  };

  const getLogoFromStore = (appId) => {
    return GetLogo(appId)
      .then((res) => {
        let logoUrl = '';
        if (res.data.logo_url) {
          logoUrl = res.data.logo_url;
        }
        setInfos({ logoUrl });
      })
      .catch(() => {
        setLoading(false);
        setErrors({
          error: "Please check your Store Id, app couldn't be found on the store.",
        });
      });
  };

  const handleClose = () => {
    setInfos(infosDefaultVal);
    setErrors({});
    setLoading(false);
    setShowForm(false);
  };
  return (
    <>
      <Restricted permission="draftGame.create">
        <Button type="button" variant="success" className="d-flex" onClick={() => setShowForm(true)} id="create-game">
          <AddIcon size={20} className="me-1" /> Submit a Game
        </Button>
      </Restricted>
      <Modal backdrop="static" centered size={'md'} show={showForm} onHide={handleClose} id="create-game-modal">
        <Modal.Header closeButton>
          <Modal.Title>Create new Game</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errors?.error && !loading && (
            <div className="alert alert-danger" role="alert">
              {errors?.error}
            </div>
          )}
          {loading ? (
            <div className="d-flex justify-content-center align-items-center">
              <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
            </div>
          ) : (
            <InfosView onNewGame errors={errors} data={infos} setData={setInfos} />
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex">
          <div className="ms-auto">
            <Button disabled={loading} variant="primary" type="button" onClick={handleNext} id="create-game-button">
              Create
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateGameModal;
