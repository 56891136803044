import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { confirmAlert } from '../../../../../Components/ConfirmModal';
import Loader from '../../../../../Components/Loader';
import { useGetApplicationMutation } from '../../../../../data/api/fbAdsApi';
import { useUpdateFacebookAppIDMutation } from '../../../../../data/api/studioApi';
import FbAppCheckView from '../../../common/views/FbAppCheckView';

const FbAppModal = ({ data, element, variant = 'primary' }) => {
  const [showModal, setShowModal] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [fbAppId, setFbAppId] = useState(data.fbAppId || '');
  const [updateFacebookAppID, { isLoading }] = useUpdateFacebookAppIDMutation();
  const [fetchFacebookApp, { data: facebookApp, isLoading: isLoadingFacebookApp, error: fbError }] =
    useGetApplicationMutation();

  useEffect(() => {
    setIsConfirmed(false);
  }, [fbAppId]);

  const handleSaveFbApp = async (e) => {
    e.stopPropagation();
    if (!isConfirmed) {
      const resp = await fetchFacebookApp(fbAppId);
      if (resp.data) setIsConfirmed(true);
      return;
    }
    updateFacebookAppID({
      fb_app_id: fbAppId,
      studio_id: data.studioId,
      bundle_id: data.bundleId,
      store: data.store === 'amazon' ? ['amazon'] : [],
    })
      .unwrap()
      .then(() => {
        setShowModal(false);
        confirmAlert({
          variant: 'success',
          title: 'Facebook App ID was successfully saved',
          confirmBtn: false,
          cancelText: 'Ok',
        }).catch(() => {});
      })
      .catch((err) => {
        confirmAlert({
          variant: 'danger',
          title: err?.data?.message || 'Failed to save Facebook App ID',
          confirmBtn: false,
          cancelText: 'Ok',
        }).catch(() => {});
      });
  };

  const handleSaveButtonClick = () => {
    setShowModal(true);
  };

  return (
    <>
      <Button variant={variant} onClick={handleSaveButtonClick} size="sm" disabled={isLoading}>
        {isLoading ? <Loader parentStyle="me-2" size={20} color="#3F96C7" /> : element}
      </Button>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Save Facebook App ID</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!isLoadingFacebookApp ? (
            <Form>
              <Form.Group controlId="facebookAppId">
                <Form.Label>Facebook App ID</Form.Label>
                <Form.Control
                  type="text"
                  value={fbAppId}
                  onChange={(e) => setFbAppId(e.target.value)}
                  placeholder="Enter Facebook App ID"
                />
              </Form.Group>
              <FbAppCheckView fbApp={facebookApp} fbAppError={fbError} />
            </Form>
          ) : (
            <div className="d-flex justify-content-center">
              <Loader parentStyle="me-2" size={150} color="#3F96C7" />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSaveFbApp} disabled={!fbAppId || isLoading}>
            {isLoading ? 'Loading...' : isConfirmed ? 'Save' : 'Confirm'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FbAppModal;
