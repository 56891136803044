import moment from 'moment';
import React, { useState } from 'react';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import { DateRange } from 'react-date-range';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import OsSelector from '../../../../../Components/OsSelector';
import SearchableDropdown from '../../../../../Components/SearchableDropdown';
import { facebookTestCountries } from '../../../../../constants/abTestCountries';
import { useUpsertAdSetMutation } from '../../../../../data/api/fbAdsApi';
import { getGameById } from '../../../../../data/slices/studio';

function SaveAdsetModal({ closeModal, campaign, client, onSuccess, adset }) {
  const isEditMode = !!adset;
  const [adSetName, setAdSetName] = useState(adset?.name || '');
  const [dailyBudget, setDailyBudget] = useState(adset?.daily_budget ? Number(adset?.daily_budget) : 100);
  const [isAlwaysOn, setIsAlwaysOn] = useState(!adset?.end_time);
  const [showDateRangeModal, setShowDateRangeModal] = useState(false);
  const [startDate, setStartDate] = useState(adset?.start_time ? new Date(adset?.start_time) : new Date());
  const [endDate, setEndDate] = useState(adset?.end_time ? new Date(adset?.end_time) : null);
  const [countries, setCountries] = useState(
    adset?.targeting ? adset?.targeting?.geo_locations?.countries : facebookTestCountries.map((x) => x.value),
  );
  const { id } = useParams();
  const foundGame = useSelector(getGameById(id));
  const [os, setOs] = useState(foundGame.os);
  const [errorMessage, setErrorMessage] = useState('');
  const [status, setStatus] = useState(adset?.status || 'ACTIVE');
  const [upsertAdset, { isLoading, isSuccess, isError }] = useUpsertAdSetMutation();
  const appUrl =
    os === 'android'
      ? 'https://play.google.com/store/apps/details?gl=US&id=' + foundGame.appId
      : 'https://apps.apple.com/us/app/' + foundGame.appName + '/id' + foundGame.appId;
  const statuses = ['ACTIVE', 'PAUSED', ...(isEditMode ? ['ARCHIVED', 'DELETED'] : [])];

  const handleSave = () => {
    if (isAlwaysOn) {
      if (!startDate) {
        setErrorMessage('Please select a start date.');
        return;
      }
    } else {
      if (!startDate || !endDate) {
        setErrorMessage('Please select a date range.');
        return;
      }
    }

    if (countries.length === 0) {
      setErrorMessage('Please select at least one country.');
      return;
    }

    setErrorMessage('');
    const adSetPayload = {
      adSetId: adset?.id,
      name: adSetName,
      clientId: client.id,
      campaignId: campaign.id,
      daily_budget: dailyBudget,
      status: status,
      start_time: moment(startDate).toISOString(),
      end_time: isAlwaysOn ? 0 : moment(endDate).toISOString(),
      targeting: {
        age_max: 65,
        age_min: 18,
        app_install_state: 'not_installed',
        brand_safety_content_filter_levels: ['FACEBOOK_STANDARD', 'AN_STANDARD'],
        targeting_automation: {
          advantage_audience: 1,
        },
        user_os: os === 'android' ? ['Android'] : ['iOS'],
        geo_locations: {
          countries: countries,
          location_types: ['home', 'recent'],
        },
      },
      ...(isEditMode
        ? {}
        : {
            optimization_goal: 'APP_INSTALLS',
            lifetime_budget: 0,
            promoted_object: {
              application_id: foundGame?.fbAppId,
              object_store_url: appUrl,
            },
            attribution_spec: [
              {
                event_type: 'CLICK_THROUGH',
                window_days: 1,
              },
              {
                event_type: 'VIEW_THROUGH',
                window_days: 1,
              },
            ],
          }),
    };

    upsertAdset(adSetPayload)
      .unwrap()
      .then((result) => {
        if (result.error) {
          let msg = `${result.error?.errorUserTitle}: ${result.error?.errorUserMsg}`;
          setErrorMessage(
            result.error?.errorUserTitle ? msg : result.error.message || 'Failed to save adset. Please try again.',
          );
        } else {
          onSuccess(result);
          closeModal();
        }
      })
      .catch((error) => {
        console.error(error);
        setErrorMessage('Failed to save adset. Please try again.');
      });
  };

  const handleOpenDateRangeModal = () => {
    setShowDateRangeModal(true);
  };

  const handleDateRangeSubmit = () => {
    setShowDateRangeModal(false);
  };

  const currencySymbol = (currencyCode) => {
    return (0)
      .toLocaleString('en-US', {
        style: 'currency',
        currency: currencyCode,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, '')
      .trim();
  };

  return (
    <>
      <Modal show onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{isEditMode ? 'Update' : 'Create'} Adset</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formAdsetName" className="pt-1 pb-3">
              <Form.Label>Adset Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter adset name"
                value={adSetName}
                onChange={(e) => setAdSetName(e.target.value)}
                required
              />
            </Form.Group>

            <div className="py-3">
              <div>
                {foundGame.multipleOs && (
                  <div className="col-12">
                    <div className="d-flex ms-auto mt-auto gap-1 align-items-center">
                      OS: <OsSelector value={os} onChange={setOs} disabled={isEditMode} className="" />
                    </div>
                  </div>
                )}
              </div>
              <div className="d-flex align-items-center gap-2 mt-2 fs-8">
                {os === 'android' ? <>Bundle ID: {foundGame.bundleId}</> : <>App ID: {foundGame.appId}</>}
                <Link className="" to={{ pathname: appUrl }} target="_blank">
                  Check store url
                </Link>
              </div>
            </div>

            <Form.Group controlId="formCountry" className="py-3">
              <Form.Label>Targeted Countries</Form.Label>
              <div className="input-group">
                <SearchableDropdown
                  multiple={true}
                  writeSelected={true}
                  selected={countries}
                  onSelect={setCountries}
                  options={facebookTestCountries}
                  placeholder={'Select Countries'}
                />
              </div>
            </Form.Group>

            <Form.Group controlId="formAdSetStatus" className="py-3">
              <Form.Label>Status</Form.Label>
              <div className="input-group">
                <Form.Select placeholder={status} onChange={(e) => setStatus(e.target.value)} value={status}>
                  {statuses.map((status, index) => (
                    <option key={index} value={status}>
                      {status}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </Form.Group>

            <Form.Group controlId="formDailyBudget" className="py-3">
              <Form.Label>Daily Budget in {client.currency}</Form.Label>
              <div className="input-group">
                <span className="input-group-text pe-0">{currencySymbol(client?.currency || 'USD')}</span>
                <Form.Control
                  className="ps-1"
                  type="number"
                  step=".01"
                  placeholder="Enter daily budget"
                  value={dailyBudget ? dailyBudget / 100 : ''}
                  onChange={(e) => setDailyBudget(Math.round(e.target.value * 100))}
                  required
                  min="1"
                />
              </div>
            </Form.Group>

            <Form.Group controlId="dateRange" className="py-3">
              <Form.Label>Start Time - End Time</Form.Label>
              <Button
                className="bg-light-400 border-0 w-100 d-flex justify-content-start"
                onClick={handleOpenDateRangeModal}
              >
                {!startDate && !endDate
                  ? 'Select Date Range'
                  : `${moment(startDate).format('MM/DD/YYYY')} - ${isAlwaysOn ? 'Never' : moment(endDate).format('MM/DD/YYYY')}`}
              </Button>
            </Form.Group>
            {errorMessage && (
              <Alert variant={'danger'} className="mt-3 mb-0">
                {errorMessage}
              </Alert>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave} disabled={isLoading}>
            {isLoading ? 'Saving...' : 'Save Adset'}
          </Button>
        </Modal.Footer>
        {!errorMessage && isSuccess && <p className="text-success ms-4">Adset saved successfully!</p>}
        {errorMessage && isError && <p className="text-danger ms-4">Failed to save adset.</p>}
      </Modal>

      <Modal centered show={showDateRangeModal} onHide={() => setShowDateRangeModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Select Date Range</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Check
            type={'checkbox'}
            label={`Always On`}
            id={'always-on-check'}
            checked={isAlwaysOn}
            onChange={(e) => {
              setStartDate(new Date());
              setEndDate(e.target.checked ? null : new Date());
              setIsAlwaysOn(e.target.checked);
            }}
          />
          <div className="d-flex flex-column align-items-center pt-3">
            <div className="d-flex justify-content-around w-100">
              <p className="fs-7 m-0">Start date</p>
              {!isAlwaysOn && <p className="fs-7 m-0">End date</p>}
            </div>
            {isAlwaysOn ? (
              <DatePicker
                id="startDate"
                className="form-control"
                selected={startDate || new Date()}
                onChange={(data) => {
                  setStartDate(data);
                  setEndDate(null);
                }}
                minDate={new Date()}
                dateFormat="MMM d, yyyy"
              />
            ) : (
              <DateRange
                ranges={[{ startDate: startDate || new Date(), endDate: endDate || new Date(), key: 'selection' }]}
                onChange={(ranges) => {
                  setStartDate(ranges.selection.startDate);
                  setEndDate(ranges.selection.endDate);
                }}
                minDate={new Date()}
              />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setShowDateRangeModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleDateRangeSubmit}>
            Apply
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SaveAdsetModal;
