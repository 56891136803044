import React from 'react';
import { Restricted } from '../../../../../Components/permissions/UserPermissions';
import UpdateAdjustAppIDModal from './UpdateAdjustAppIDModal';

const AdjustApp = ({ data }) => {
  return (
    <>
      <div className="d-flex">
        {data?.adjustAppToken}
        <Restricted permission="adjustApp.update">
          <UpdateAdjustAppIDModal appId={data.appId} adjustAppToken={data.adjustAppToken} studioId={data?.studioId} />
        </Restricted>
      </div>
    </>
  );
};

export default AdjustApp;
