import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import GuardedRoute from './Modules/GuardedRoute';
import AbtestMetrics from './Pages/AbTest/GameAbtestMetrics/GameAbtestMetrics';
import AbTestGameList from './Pages/AbTest/GameList/AbTestGameList';
import ForgotPassword from './Pages/Auth/ForgotPassword';
import Login from './Pages/Auth/Login';
import SignUp from './Pages/Auth/SignUp';
import Dashboard from './Pages/Dashboard/Dashboard';
import Home from './Pages/Game/GameList/GameList';
import MarketInsight from './Pages/MarketInsight/MarketInsight';
import Sdk from './Pages/Sdk';
import TermsAndConditons from './Pages/TermsAndConditons';

import {
  R_AB_TEST,
  R_AB_TEST_METRICS,
  R_AB_TESTS,
  R_ACCOUNT,
  R_AI_TOOLS,
  R_ANALYTICS_ACQUISITION,
  R_ANALYTICS_ENGAGEMENT,
  R_ANALYTICS_MONETIZATION,
  R_DASHBOARD,
  R_FORGOT_PASSWORD,
  R_GAME,
  R_GAMES,
  R_HOME,
  R_LOGIN,
  R_MARKET_INSIGHT,
  R_OVERVIEW_ANALYTICS,
  R_SDK,
  R_SIGN_UP,
  R_TERMS_CONDITIONS,
  R_TOP_APPS,
  R_USER_MANAGEMENT,
} from './constants/routes';
import GameAbtestList from './Pages/AbTest/GameAbtestList/GameAbtestList';
import UpdateAccount from './Pages/Account/UpdateAccount/UpdateAccount';
import AiTools from './Pages/AITools/AiTools';
import Acquisition from './Pages/Analytics/Acquisition/Acquisition';
import Engagement from './Pages/Analytics/Engagement/Engagement';
import Monetization from './Pages/Analytics/Monetization/Monetization';
import OverviewAnalytics from './Pages/Analytics/Overview/Overview';
import DevPage from './Pages/DevPage';
import Game from './Pages/Game/Game/Game';
import TopApps from './Pages/TopApps/TopApps';
import UserManagement from './Pages/UserManagement/UserManagement';

const App = () => {
  return (
    <Router>
      <Switch>
        <GuardedRoute exact path={R_SDK} title="SDK" component={Sdk} footer={false} />

        <GuardedRoute exact path={R_MARKET_INSIGHT} title="Market Insights" component={MarketInsight} />

        <GuardedRoute exact path={R_TOP_APPS} title="Top Charts - US" component={TopApps} />

        <GuardedRoute path={R_AI_TOOLS} component={AiTools} />

        <GuardedRoute path={R_GAME} component={Game} />

        <GuardedRoute exact path={R_AB_TESTS} title="A/B Tests" component={AbTestGameList} />
        <GuardedRoute path={R_AB_TEST} title="A/B Test" back={R_AB_TESTS} component={GameAbtestList} />
        <GuardedRoute path={R_AB_TEST_METRICS} component={AbtestMetrics} />

        <GuardedRoute exact path={R_ANALYTICS_MONETIZATION} title="Monetization" component={Monetization} />
        <GuardedRoute exact path={R_ANALYTICS_ACQUISITION} title="User Acquisition" component={Acquisition} />
        <GuardedRoute exact path={R_OVERVIEW_ANALYTICS} title="Overview" component={OverviewAnalytics} />
        <GuardedRoute exact path={R_ANALYTICS_ENGAGEMENT} title="User Engagement" component={Engagement} />

        <GuardedRoute exact path={R_ACCOUNT} title="Edit Account" back="goBack" component={UpdateAccount} />

        <GuardedRoute permission={'user.list'} path={R_USER_MANAGEMENT} component={UserManagement} />

        <Route exact path={R_SIGN_UP} component={SignUp} />

        <Route exact path={R_LOGIN} component={Login} />

        <Route exact path={R_FORGOT_PASSWORD} component={ForgotPassword} />

        <Route exact path={R_TERMS_CONDITIONS} component={TermsAndConditons} />

        <GuardedRoute permission={'dev.page'} path={'/dev/'} component={DevPage} />

        <GuardedRoute exact path={R_GAMES} title="Games" component={Home} />
        <GuardedRoute path={R_DASHBOARD} title="Dashboard" component={Dashboard} />
        <Redirect to={R_HOME} />
      </Switch>
    </Router>
  );
};

export default App;
