import React, { useEffect, useState } from 'react';
import { Button, InputGroup, Nav, Navbar } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useHistory } from 'react-router-dom';
import SearchIcon from '../Components/Icons/SearchIcon';
import { GAME_CATEGORIES, GAME_KEYWORDS, GAME_PLATFORMS } from '../constants/game';
import { BIZ_DEV_EMAIL_LIST, MONTHS } from '../constants/global';
import { useQuery } from '../utils/customHooks';

export default function GameSearchFilter() {
  const query = useQuery();
  const history = useHistory();
  const location = history.location;
  const [search, setSearch] = useState('');

  useEffect(() => {
    const searchParam = query.get('search') || '';
    setSearch(searchParam);
  }, [query]);

  const handleChange = (name, value) => {
    if (query.has('page')) query.delete('page');
    if (!value) query.delete(name);
    else query.set(name, value);
    history.push(history.location.pathname + '?' + query.toString());
  };
  const gameName = query.get('search') || '',
    bizDevEmail = query.get('email') || '',
    categorySelected = query.get('category') || '',
    status = query.get('status') || '',
    tags = query.get('tags') || '',
    year = query.get('year') || '',
    month = query.get('month') || '',
    os = query.get('os') || '';

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value;
    setSearch(searchTerm);
    handleChange('search', searchTerm);
  };

  const clearFilters = () => {
    history.push(history.location.pathname);
  };

  const isFiltersEmpty = () => {
    return !(
      !!gameName ||
      !!search ||
      !!bizDevEmail ||
      !!categorySelected ||
      !!status ||
      !!tags ||
      !!year ||
      !!month ||
      os
    );
  };

  const abtestStatus = ['Pending'];

  return (
    <div className="mt-2">
      <div className="data-filter-container">
        <Navbar className="p-0" bg="bg-transparent" expand="lg">
          <div className="d-flex w-100 justify-content-between d-lg-none">
            <Navbar.Brand className="d-lg-none p-0" href="#home">
              Filters
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
          </div>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="align-items-center">
              <Nav.Item className="dropdown">
                <Form.Group>
                  <InputGroup>
                    <Form.Control
                      className="bg-white"
                      type="text"
                      aria-describedby=""
                      placeholder="Search..."
                      value={search}
                      onChange={handleSearchChange}
                      autoComplete={'off'}
                    />
                    <InputGroup.Text className="bg-white">
                      <SearchIcon size={20} />
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              </Nav.Item>
              <Nav.Item className="dropdown">
                <Form.Group className="input-group select-drop">
                  <Form.Select
                    className="form-select bg-white"
                    value={year}
                    onChange={(event) => handleChange('year', event.target.value)}
                  >
                    <option defaultValue value={''}>
                      Year
                    </option>
                    <option value={2024}>2024</option>
                    <option value={2023}>2023</option>
                    <option value={2022}>2022</option>
                    <option value={2021}>2021</option>
                    <option value={2020}>2020</option>
                  </Form.Select>
                </Form.Group>
              </Nav.Item>
              <Nav.Item className="dropdown">
                <Form.Group className="input-group select-drop">
                  <Form.Select
                    className="form-select bg-white"
                    value={month}
                    onChange={(event) => handleChange('month', event.target.value)}
                  >
                    <option defaultValue value={''}>
                      Month
                    </option>
                    {MONTHS.map((value, index) => (
                      <option value={value} key={index}>
                        {value}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Nav.Item>
              {location.pathname === '/abtests' && (
                <Nav.Item className="dropdown">
                  <Form.Group className="input-group select-drop">
                    <Form.Select
                      className="form-select bg-white"
                      value={status}
                      onChange={(event) => handleChange('status', event.target.value)}
                    >
                      <option defaultValue value={''}>
                        Status
                      </option>
                      {abtestStatus.map((value, index) => (
                        <option value={value} key={index}>
                          {value}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Nav.Item>
              )}
              <Nav.Item className="dropdown">
                <Form.Group className="input-group select-drop">
                  <Form.Select
                    className="form-select bg-white"
                    value={tags}
                    onChange={(e) => handleChange('tags', e.target.value)}
                  >
                    <option defaultValue value={''}>
                      Tags
                    </option>
                    {GAME_KEYWORDS.map((value, index) => (
                      <option value={value} key={index}>
                        {value}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Nav.Item>
              <Nav.Item className="dropdown">
                <Form.Group className="input-group select-drop">
                  <Form.Select
                    className="form-select bg-white"
                    value={os}
                    onChange={(e) => handleChange('os', e.target.value)}
                  >
                    <option defaultValue value={''}>
                      OS
                    </option>
                    {GAME_PLATFORMS.map((value, index) => (
                      <option value={value.toLowerCase()} key={index}>
                        {value}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Nav.Item>
              <Nav.Item className="dropdown">
                <Form.Group className="input-group select-drop">
                  <Form.Select
                    className="form-select bg-white"
                    value={categorySelected}
                    onChange={(event) => handleChange('category', event.target.value)}
                  >
                    <option defaultValue value={''}>
                      Category
                    </option>
                    {GAME_CATEGORIES.map((value, index) => (
                      <option value={value} key={index}>
                        {value}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Nav.Item>
              <Nav.Item className="dropdown">
                <Form.Group className="input-group select-drop">
                  <Form.Select
                    className="form-select bg-white"
                    value={bizDevEmail}
                    onChange={(event) => handleChange('email', event.target.value)}
                  >
                    <option defaultValue value={''}>
                      Contact
                    </option>
                    {BIZ_DEV_EMAIL_LIST.map((value, index) => (
                      <option value={value} key={index}>
                        {value}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Nav.Item>
              {!isFiltersEmpty() && (
                <Nav.Item className="dropdown">
                  <Button variant="dark" size="sm" onClick={clearFilters} className="fs-8 text-nowrap w-100">
                    Clear Filters
                  </Button>
                </Nav.Item>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  );
}
