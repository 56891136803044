import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Dropdown, Form, InputGroup } from 'react-bootstrap';
import { useDebounce } from '../utils/useDebounce';
import Loader from './Loader';

const DynamicSelect = ({
  selected,
  onChange,
  searchFunction,
  placeholder = 'Search...',
  disabled = false,
  debounceTime = 1000,
  labelFormatter = (option) => option.label,
}) => {
  const [options, setOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, debounceTime);
  const wrapperRef = useRef(null);
  const inputRef = useRef(null);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setSearchTerm('');
      setOptions([]);
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const performSearch = useCallback(async () => {
    setIsLoading(true);
    try {
      setOptions(await searchFunction(debouncedSearchTerm));
    } catch (error) {
      console.error('Search error:', error);
      setOptions([]);
    } finally {
      setIsLoading(false);
    }
  }, [debouncedSearchTerm, searchFunction]);

  useEffect(() => {
    if (showDropdown) {
      performSearch();
    }
  }, [performSearch, showDropdown]);

  const handleInputChange = (e) => {
    setIsLoading(true);
    setSearchTerm(e.target.value);
    setShowDropdown(true);
  };

  const handleInputClick = () => {
    setShowDropdown(true);
  };

  const handleSelect = (option) => {
    onChange(option);
    setSearchTerm('');
    setOptions([]);
    setShowDropdown(false);
  };

  const handleClear = () => {
    onChange(null);
    handleInputClick();
    setTimeout(() => {
      inputRef?.current?.focus();
    }, 0);
  };

  return (
    <div className="position-relative" ref={wrapperRef}>
      <InputGroup>
        {selected ? (
          <>
            <InputGroup.Text className="cursor-default">{labelFormatter(selected)}</InputGroup.Text>
            {!disabled && (
              <InputGroup.Text className="bg-white cursor-pointer" onClick={handleClear}>
                ×
              </InputGroup.Text>
            )}
          </>
        ) : (
          <Form.Control
            type="text"
            value={searchTerm}
            onChange={handleInputChange}
            onClick={handleInputClick}
            placeholder={placeholder}
            disabled={disabled}
            ref={inputRef}
          />
        )}
      </InputGroup>

      {isLoading && (
        <div className="position-absolute w-100 text-center mt-1">
          <Loader size={30} color="#6c757d" />
        </div>
      )}

      {options.length > 0 && showDropdown && !isLoading && (
        <Dropdown.Menu
          show
          style={{
            maxWidth: '200%',
            maxHeight: '200px',
            overflowY: 'auto',
          }}
        >
          {options.map((option, index) => (
            <Dropdown.Item
              key={index}
              onClick={() => handleSelect(option)}
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {labelFormatter(option)}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      )}
    </div>
  );
};

export default DynamicSelect;
