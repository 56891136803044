import { Button } from 'react-bootstrap';

const OsSelector = ({ value, onChange, disabled = false }) => {
  const options = [
    {
      label: 'Android',
      value: 'android',
    },
    {
      label: 'IOS',
      value: 'ios',
    },
  ];

  return (
    <div className="d-flex mt-auto gap-1">
      {options.map((x) => (
        <Button
          key={x.value}
          size="sm"
          onClick={() => onChange(x.value)}
          variant={value === x.value ? 'primary' : 'outline-secondary'}
          disabled={disabled}
        >
          {x.label}
        </Button>
      ))}
    </div>
  );
};

export default OsSelector;
